import React from 'react';
import { calculateStepSize, formatValue } from '../utils/helpers';
import { BarChart } from '../shared';

function stackBarDatas(operatingExpensesMonths) {
    // Function to generate background color (simple version)
    function generateBackgroundColor(index) {
        const colors = ['#8633FF', '#FF1F6A', '#00C791', '#3381E2'];
        return colors[index % colors.length];
    }

    // Map through each month to get labels and datasets for the chart
    const labels = operatingExpensesMonths.map((month) => month.month);
    let datasets = [];

    // Assuming all months have the same expense categories
    if (
        operatingExpensesMonths.length > 0 &&
        operatingExpensesMonths[0].dataset.length > 0
    ) {
        operatingExpensesMonths[0].dataset.forEach((expense, index) => {
            const data = operatingExpensesMonths.map(
                (month) =>
                    month.dataset.find((e) => e.account === expense.account)
                        ?.amount || 0,
            );

            datasets.push({
                label: expense.account,
                data: data,
                backgroundColor: generateBackgroundColor(index),
                borderRadius: 4,
                barThickness: 30,
                maxBarThickness: 40,
            });
        });
    }

    return {
        labels,
        datasets,
    };
}

export const OperatingExpensesChart = ({ data, isDarkMode }) => {
    const transformedData = stackBarDatas(data.operating_expenses_months);
    const datasets = transformedData.datasets;
    const maxData =
        datasets.length > 0
            ? Math.max(...datasets.flatMap((dataset) => dataset.data))
            : 0;
    const minData =
        datasets.length > 0
            ? Math.min(...datasets.flatMap((dataset) => dataset.data))
            : 0;
    const range = maxData - minData;
    const stepSize = calculateStepSize(range, 2);

    const stackBarOptions = {
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: true,
            },
            title: {
                display: false,
            },
            datalabels: {
                display: false,
            },
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
                grid: {
                    display: false,
                },
            },
            y: {
                stacked: true,
                grid: {
                    color: isDarkMode ? '#6C727F' : '#E5E6EB',
                },
                ticks: {
                    callback: function (value) {
                        return formatValue(value);
                    },
                    stepSize: stepSize,
                    max: maxData + stepSize,
                    min: minData > 0 ? minData - stepSize : 0,
                },
            },
        },
    };

    return <BarChart options={stackBarOptions} data={transformedData} />;
};
