import { Grid } from '@mui/material';
import React from 'react';
import { Typography } from './Typography';
import { Switch } from './Switch';
import { useNonAccountantMode } from '../hooks/useNonAccountantMode';

export const NonAccountantMode = ({ label }) => {
    const { nonAccountantMode, toggleNonAccountantMode } =
        useNonAccountantMode();
    return (
        <Grid
            item
            xs={12}
            container
            sx={{
                padding: '16px',
                alignItems: 'center',
                border: (theme) => `1px solid ${theme.borderColor.inputField}`,
                borderRadius: '8px',
            }}
        >
            <Grid item xs={10}>
                <Typography
                    text={label}
                    customStyles={{
                        color: (theme) => theme.textColor.dark,
                        fontSize: '14px',
                        fontWeight: 500,
                    }}
                />
            </Grid>
            <Grid item xs={2} display={'flex'} justifyContent={'flex-end'}>
                <Switch
                    checked={nonAccountantMode}
                    onClick={toggleNonAccountantMode}
                    labelPlacement="start"
                />
            </Grid>
        </Grid>
    );
};
