import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Modal, TextField, Typography } from '../../shared';
import { capitalize, isObjectEmpty } from '../../utils/helpers';
// import { sampleTableData } from '../../utils/data';

function ManageAccountModal(props) {
    const {
        open,
        title,
        account,
        btnTitle,
        showFooter,
        handleCloseModal,
        handleManageAccount,
    } = props;

    const [selectedAccount, setSelectedAccount] = useState();
    // const [isTypeDisabled, setIsTypeDisabled] = useState(false);
    // const handleParentAccountChange = (e) => {
    //     console.log(e.target.textContent);
    //     setIsTypeDisabled((prevState) => !prevState);
    // };

    useEffect(() => {
        if (account) {
            setSelectedAccount(account);
        }
    }, [account]);

    const isEdit = !isObjectEmpty(account);

    const handleUpdateAccount = () => {
        handleManageAccount(
            selectedAccount.id,
            selectedAccount.note_to_bookkeeper,
        );
    };
    return (
        <Modal
            dividers
            fullWidth
            open={open}
            title={title}
            showFooter={showFooter}
            actionBtnTitle={btnTitle}
            handleClose={handleCloseModal}
            actionHandler={handleUpdateAccount}
            // disabledBtn={isSubmitButtonDisabled(formik.touched, formik.errors)}
            // loading={loading}
        >
            <Grid container spacing={2.8}>
                {/* <Grid item xs={12}>
                    <Autocomplete
                        options={sampleTableData.map((data) => {
                            return {
                                label: data.name,
                                value: data.id,
                            };
                        })}
                        labelKey="label"
                        valueKey="value"
                        placeholder="Parent Account"
                        name="parentAccount"
                        isSearchField={false}
                        label={'Parent Account'}
                        onChange={handleParentAccountChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        name="accountName"
                        placeholder="Account Name "
                        type="text"
                        label="Account Name"
                        // value={email}
                        // onChange={(e: { target: { value: SetStateAction<string> } }) => setEmail(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        name="type"
                        placeholder="Type "
                        type="text"
                        label="Type"
                        disabled={isTypeDisabled}
                        // value={email}
                        // onChange={(e: { target: { value: SetStateAction<string> } }) => setEmail(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Autocomplete
                        options={[
                            {
                                label: 'Accrual',
                                value: 'accrual',
                            },
                            {
                                label: 'Cash',
                                value: 'cash',
                            },
                            {
                                label: 'Accrual / Cash',
                                value: 'accrual-cash',
                            },
                        ]}
                        labelKey="label"
                        valueKey="value"
                        placeholder="Basis"
                        name="basis"
                        isSearchField={false}
                        label={'Select Basis'}
                        onChange={() => {}}
                    />
                </Grid> */}

                {isEdit ? (
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            name="note_to_bookkeeper"
                            placeholder="Memo for Bookkeeper (Vendors)"
                            type="text"
                            label="Memo for Bookkeeper (Vendors)"
                            value={capitalize(
                                selectedAccount?.note_to_bookkeeper,
                            )}
                            onChange={(e) => {
                                setSelectedAccount({
                                    ...(selectedAccount ?? account),
                                    [e.target.name]: e.target.value,
                                });
                            }}
                        />
                    </Grid>
                ) : (
                    <Grid item xs={12}>
                        <Typography
                            text="To add custom accounts, please contact support@circler.io"
                            emailLink={{
                                email: 'support@circler.io',
                                newTab: false,
                            }}
                        />
                    </Grid>
                )}
                {/* <Grid item xs={12}>
                    <TextField
                        fullWidth
                        name="description"
                        placeholder="Description "
                        type="text"
                        label="Description"
                        multiline
                        minRows={5}
                        // value={email}
                        // onChange={(e: { target: { value: SetStateAction<string> } }) => setEmail(e.target.value)}
                    />
                </Grid> */}
            </Grid>
        </Modal>
    );
}

export default ManageAccountModal;
