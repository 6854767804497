import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    meLoading: false,
    data: null,
    error: null,
};

const prepare = (payload, meta, error) => {
    return { payload: { ...payload }, meta: { ...meta }, error: { ...error } };
};
export const meSlice = createSlice({
    name: 'me',
    initialState,
    reducers: {
        GET_AUTH_ME: {
            reducer: () => {},
            prepare,
        },
        SET_CURRENT_USER_PENDING: {
            reducer: (state, { payload }) => {
                state.meLoading = payload.loading;
            },
            prepare,
        },
        SET_CURRENT_USER_SUCCESS: {
            reducer: (state, { payload }) => {
                state.data = payload.data;
                state.meLoading = false;
            },
            prepare,
        },
        SET_CURRENT_USER_ERROR: {
            reducer: (state, { payload }) => {
                state.error = payload.err;
                state.meLoading = false;
            },
            prepare,
        },
    },
});

export const {
    GET_AUTH_ME,
    SET_CURRENT_USER_SUCCESS,
    SET_CURRENT_USER_PENDING,
    SET_CURRENT_USER_ERROR,
} = meSlice.actions;

export default meSlice.reducer;
