import { useState } from 'react';
import {
    Divider,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    Typography,
    Link,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import Image from 'mui-image';
import CirclerImage from '../assets/icons/CirclerLeftImage.svg';
import { ReactComponent as DarkCirclerLogo } from '../assets/icons/DarkLogo.svg';
import { ReactComponent as CirclerLogo } from '../assets/icons/circler-logo.svg';
import GoogleIcon from '../assets/icons/google-icon.png';
import { TextField, Button } from '../shared';
import { useNavigate } from 'react-router-dom';
import { useThemeToggle } from '../hooks/useThemeToggle';
import { CheckboxSelection as Checkbox } from '../shared';
import { useCustomMediaQuery } from '../hooks/useMediaQuery';
import {
    LOG_IN_PENDING,
    LOG_IN_ERROR,
    SAVE_TOKEN,
    LOG_IN_SUCCESS,
} from '../store/actions';
import { useAuthService } from '../store/services/auth.service';
import useNotification from '../hooks/useNotification';

const styles = {
    container: {
        height: '100vh',
        overflow: 'hidden',
    },
    mainHeading: {
        fontSize: '32px',
        fontWeight: '700',
        color: (theme) => theme.textColor.dark,
        lineHeight: '38px',
    },
    subHeading: {
        fontSize: '18px',
        fontWeight: '500',
        color: '#6C727F',
        lineHeight: '24px',
        mt: 0.5,
    },

    forgotPassword: {
        color: (theme) =>
            theme.palette.mode === 'dark' ? '#3381E2' : '#0061DB',
        cursor: 'pointer',
        fontSize: '16px',
        fontWeight: 500,
    },
    loginBtnStyle: {
        borderRadius: '8px',
        fontSize: '18px',
        fontWeight: 500,
    },
    otherSignInOptionsContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    otherSignInOptionsText: {
        fontSize: '16px',
        fontWeight: '500',
        lineHeight: '24px',
        color: (theme) =>
            theme.palette.mode === 'dark' ? '#9EA3AE' : '#6C727F',
    },
    signInOption: {
        color: '#FFF',
        fontSize: '16px',
        fontWeight: '500',
    },
    googleSignInOption: {
        color: (theme) => theme.textColor.dark,
        fontSize: '16px',
        fontWeight: '500',
    },
    otherSignInOption: {
        color: (theme) => theme.textColor.dark,
        fontSize: '16px',
        fontWeight: '400',
    },
    link: {
        textDecoration: 'none',
        color: (theme) =>
            theme.palette.mode === 'dark' ? '#3381E2' : '#0061DB',
        // color: '#004EAF', // Blue color
        cursor: 'pointer', // Optional: Change cursor on hover,
        fontWeight: 600,
        fontSize: '16px',
    },
};

export const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [sendNotification] = useNotification();
    const { login, isLoading } = useAuthService();
    const { themeMode } = useThemeToggle();
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [password, setPassword] = useState('');
    const [passwordVisible, setPasswordVisible] = useState(false);
    const isScreenLowerThan1024 = useCustomMediaQuery('(max-width: 1025px)');
    const handleTogglePasswordVisibility = () => {
        setPasswordVisible((prev) => !prev);
    };

    const handleLogin = async () => {
        dispatch(LOG_IN_PENDING());
        login(email.toLowerCase(), password)
            .then((res) => {
                if (res.is_active) {
                    dispatch(LOG_IN_SUCCESS());
                    dispatch(SAVE_TOKEN({ token: res.token }));
                    res.is_onboarded
                        ? navigate('/home')
                        : navigate('/onboarding');
                } else {
                    sendNotification({
                        msg: 'Your account is not active yet.',
                        variant: 'error',
                    });
                }
            })
            .catch((err) => {
                dispatch(LOG_IN_ERROR({ error: err.errorMessage }));
                setError(err.errorMessage);
            });
    };

    return (
        <Grid
            flex={{ xs: '1', sm: '2', md: '3' }}
            container
            sx={styles.container}
        >
            <Grid
                item
                position="relative"
                xs={12}
                md={6}
                lg={6}
                maxWidth={'700px'}
                display={{ xs: 'none', md: 'block', lg: 'block' }}
                overflow={{ xs: 'scroll', md: 'hidden', lg: 'hidden' }}
            >
                <Image alt="Left Content" src={CirclerImage} />
            </Grid>
            <Grid
                container
                item
                xs={12}
                md={6}
                lg={6}
                flex="1"
                alignContent="flex-start"
                justifyContent={'center'}
                px={{ xs: 2, sm: 4, md: 8 }}
                pt="50px"
                sx={{
                    // height: 'calc(100vh - 25px)',
                    overflow: 'auto',
                    backgroundColor: (theme) => theme.backgroundColor.primary,
                }}
            >
                <Grid
                    item
                    container
                    direction="column"
                    maxWidth={'700px'}
                    padding={isScreenLowerThan1024 ? '24px 0' : '0 10%'}
                    gap={3}
                    textAlign={'start'}
                >
                    <Grid item>
                        {themeMode === 'dark' ? (
                            <DarkCirclerLogo
                                width="180.995px"
                                height="45.22px"
                            />
                        ) : (
                            <CirclerLogo width="180.995px" height="45.22px" />
                        )}
                    </Grid>
                    <Grid item>
                        <Typography sx={styles.mainHeading}>
                            Sign in to your account
                        </Typography>
                        <Typography sx={styles.subHeading}>
                            Please enter your details.
                        </Typography>
                    </Grid>

                    <Grid item>
                        <TextField
                            name="email"
                            placeholder="Email *"
                            type="text"
                            value={email}
                            onChange={(e: {
                                target: { value: SetStateAction<string> },
                            }) => setEmail(e.target.value)}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            name="password"
                            label="Password"
                            placeholder="Password *"
                            value={password}
                            passwordVisible={passwordVisible}
                            type="password"
                            onChange={(e: {
                                target: { value: SetStateAction<string> },
                            }) => {
                                setPassword(e.target.value);
                            }}
                            InputProps={{
                                onKeyDown: (
                                    e: KeyboardEvent<HTMLInputElement>,
                                ) => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault();
                                        handleLogin();
                                    }
                                },
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={
                                                handleTogglePasswordVisibility
                                            }
                                            edge="end"
                                        >
                                            {passwordVisible ? (
                                                <VisibilityOutlinedIcon fontSize="small" />
                                            ) : (
                                                <VisibilityOffOutlinedIcon fontSize="small" />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>

                    <Grid container item justifyContent="space-between">
                        <Grid item>
                            {/* <FormControlLabel
                                sx={{ padding: 0 }}
                                control={<Checkbox />}
                                label={
                                    <Typography
                                        sx={{
                                            fontSize: '16px',
                                            fontWeight: 500,
                                            color: (theme) =>
                                                theme.textColor.dark,
                                        }}
                                    >
                                        Remember me
                                    </Typography>
                                }
                            /> */}
                        </Grid>
                        <Grid
                            item
                            sx={{
                                display: 'flex',
                                alignContent: 'center',
                                flexWrap: 'wrap',
                            }}
                        >
                            <Typography sx={styles.forgotPassword}>
                                <Link href="/forgotpassword" sx={styles.link}>
                                    Forgot Password?
                                </Link>
                            </Typography>
                        </Grid>
                    </Grid>
                    {error && (
                        <Grid item>
                            <Typography
                                sx={{
                                    color: 'red',
                                    fontSize: 16,
                                    fontWeight: 500,
                                }}
                            >
                                {error}
                            </Typography>
                        </Grid>
                    )}
                    <Grid item>
                        <Button
                            text={
                                <Typography sx={styles.signInOption}>
                                    Sign In
                                </Typography>
                            }
                            fullWidth
                            loading={isLoading}
                            customStyles={styles.loginBtnStyle}
                            onClick={handleLogin}
                        />
                    </Grid>

                    <Grid
                        container
                        item
                        justifyContent="space-between"
                        sx={styles.otherSignInOptionsContainer}
                    >
                        {/* <Grid item width={{ xs: '20%', md: '15%', lg: '30%' }}>
                            <Divider
                                sx={{
                                    background: (theme) =>
                                        theme.palette.mode === 'dark'
                                            ? '#394150'
                                            : '#E5E6EB',
                                }}
                            />
                        </Grid> */}
                        {/* <Grid item>
                            <Typography sx={styles.otherSignInOptionsText}>
                                Or sign in with
                            </Typography>
                        </Grid>
                        <Grid item width={{ xs: '20%', md: '15%', lg: '30%' }}>
                            <Divider
                                sx={{
                                    background: (theme) =>
                                        theme.palette.mode === 'dark'
                                            ? '#394150'
                                            : '#E5E6EB',
                                }}
                            />
                        </Grid> */}
                    </Grid>
                    {/* <Grid item>
                        <Button
                            text={
                                <Typography sx={styles.googleSignInOption}>
                                    Google
                                </Typography>
                            }
                            fullWidth
                            variant="outlined"
                            customStyles={{
                                ...styles.loginBtnStyle,
                                backgroundColor: 'transparent',
                                color: '#121826',
                                fontSize: '16px',
                                fontStyle: 'normal',
                                fontWeight: 500,
                                lineHeight: '24px',
                            }}
                            startIcon={
                                <img
                                    src={GoogleIcon}
                                    alt="Google icon"
                                    height={'22px'}
                                    width={'22px'}
                                />
                            }
                        />
                    </Grid> */}

                    <Grid item textAlign={'center'}>
                        <Typography sx={styles.otherSignInOption}>
                            Don't have an account?&nbsp;
                            <Link href="/signup" sx={styles.link}>
                                Get Started
                            </Link>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
