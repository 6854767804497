// NonAccountantModeContext.js
import React, { createContext, useState, useContext } from 'react';

const NonAccountantModeContext = createContext();

const NonAccountantModeProvider = ({ children }) => {
    const [nonAccountantMode, setNonAccountantMode] = useState(false);

    const toggleNonAccountantMode = () => {
        setNonAccountantMode((prevMode) => !prevMode);
    };

    return (
        <NonAccountantModeContext.Provider
            value={{ nonAccountantMode, toggleNonAccountantMode }}
        >
            {children}
        </NonAccountantModeContext.Provider>
    );
};

const useNonAccountantMode = () => {
    const context = useContext(NonAccountantModeContext);
    if (!context) {
        throw new Error(
            'useNonAccountantMode must be used within a NonAccountantModeProvider',
        );
    }
    return context;
};

export { NonAccountantModeProvider, useNonAccountantMode };
