import { format } from 'date-fns';
import { isEmpty } from 'lodash';

// Shows the bytes in MB, KB or B
export const formatBytes = (bytes: string | number) => {
    const numericBytes = +bytes;
    if (numericBytes >= 1024 * 1024) {
        return `${(numericBytes / (1024 * 1024)).toFixed(2)} MB`;
    } else if (numericBytes >= 1024) {
        return `${(numericBytes / 1024).toFixed(2)} KB`;
    } else {
        return `${numericBytes} Bytes`;
    }
};

// This function passes the component height to Filestack so
// it can return a lighter optimized version of the image
export const optimizeImg = (imageUrl: string, height: number) => {
    const filestackUrl = 'https://cdn.filestackcontent.com/';
    const isFilestackUrl = imageUrl.match(filestackUrl);
    if (isFilestackUrl) {
        const index = filestackUrl.length;
        // Add extra height to guarantee resolution quality
        const newHeight = height + 300;
        return `${imageUrl.substring(
            0,
            index,
        )}resize=height:${newHeight}/${imageUrl.substring(index)}`;
    } else {
        return imageUrl;
    }
};

//'MM-DD-YYYY'
export const formateDate = (date: string, dateFormat) => {
    return format(new Date(date), dateFormat);
};

export const isObjectEmpty = (data) => {
    return isEmpty(data);
};

export const randomRange = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
};

// to always return type string event when s may be falsy other than empty-string
export const capitalize = (s) => (s && s[0].toUpperCase() + s.slice(1)) || '';

export const getRandomColor = () => {
    const colors = ['#00C791', '#0061DB', '#8633FF', '#FF1F6A'];
    const randomIndex = Math.floor(Math.random() * colors.length);
    return colors[randomIndex];
};

export const formatValue = (value) => {
    const absValue = Math.abs(value);
    let formattedValue;

    if (absValue >= 1e9) {
        // Billion
        formattedValue = `$${(absValue / 1e9).toFixed(1)}B`;
    } else if (absValue >= 1e6) {
        // Million
        formattedValue = `$${(absValue / 1e6).toFixed(1)}M`;
    } else if (absValue >= 1000) {
        // Thousand
        formattedValue = `$${(absValue / 1000).toFixed(1)}K`;
    } else {
        // Less than 1000
        formattedValue = `$${absValue}`;
    }

    return value < 0 ? `-${formattedValue}` : formattedValue;
};

export const calculateStepSize = (range, desiredTickCount) => {
    const roughStepSize = range / (desiredTickCount - 1);
    const magnitude = Math.floor(Math.log10(roughStepSize));
    const magnitudePower = Math.pow(10, magnitude);
    const roundedRoughStepSize =
        Math.ceil(roughStepSize / magnitudePower) * magnitudePower;
    return roundedRoughStepSize;
};

export const downloadFile = (data, fileName) => {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${fileName}.csv`);
    document.body.appendChild(link);
    link.click();
    link.remove();
};
