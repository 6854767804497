import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { Route, Routes } from 'react-router-dom';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import * as jose from 'jose';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import { useNavigate } from 'react-router-dom';
import MuiAppBar from '@mui/material/AppBar';
import { styled, Theme, CSSObject, ThemeProvider } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Close from '@mui/icons-material/Close';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import { Divider, Grid, useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Avatar from '@mui/material/Avatar';
// import Badge from '@mui/material/Badge';
import { Typography, Menu, NonAccountantMode } from './shared';
import { ReactComponent as DarkMode } from './assets/icons/DarkMode.svg';
import { ReactComponent as DarkModeIcon } from './assets/icons/DarkModeToggle.svg';
// import { ReactComponent as Notification } from './assets/icons/Notification.svg';
// import { ReactComponent as NotificationDarkMode } from './assets/icons/NotificationDarkMode.svg';
import { ReactComponent as UserProfile } from './assets/icons/UserProfile.svg';
import { ReactComponent as UserProfileDarkMode } from './assets/icons/UserProfileDarkMode.svg';
import { ReactComponent as Logout } from './assets/icons/Logout.svg';
import { Switch } from './shared/Switch';
import { lightTheme, darkTheme } from './assets/theme';
import { useThemeToggle } from './hooks/useThemeToggle';
import { PRIVATE_ROUTES, PUBLIC_ROUTES } from './routes';
import { useAuth } from './hooks/useAuth';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { SwipeableFromLeftDrawer } from './components';
import Sidebar from './shared/Sidebar';
import './App.scss';
import { useAuthService } from './store/services/auth.service';
import { useDispatch, useSelector } from 'react-redux';
import { isObjectEmpty } from './utils/helpers';
import {
    GET_AUTH_ME,
    LOGOUT,
    UPDATE_COMPANY_ERROR,
    UPDATE_COMPANY_PENDING,
    UPDATE_COMPANY_SUCCESS,
} from './store/actions';
import { useCompanyService } from './store/services/company.service';

const drawerWidth = 230;

const makeStyles = ({
    isDrawerOpen,
    isScreenLowerThan1024,
    themeMode,
    isOnboarding,
}) => ({
    toolBar: {
        transition: 'ease-in-out 300ms',
        '&. MuiToolbar-root': {
            minHeight: 53,
        },
        display: 'flex',
        alignContent: 'center',
        padding: isScreenLowerThan1024 ? '20px' : '20px 40px 20px 10px',
        left: isDrawerOpen
            ? isScreenLowerThan1024
                ? 0
                : 230
            : isScreenLowerThan1024
            ? 0
            : 64,
        backgroundColor: (theme) => theme.backgroundColor.primary,
        width: isScreenLowerThan1024
            ? `100%`
            : `calc(100vw - ${isDrawerOpen ? '215px' : '50px'})`,
        borderBottom: (theme) =>
            theme.palette.mode === 'light'
                ? '1px solid #E5E6EB'
                : '1px solid #394150',
    },
    drawerPaper: {
        borderRight: (theme) =>
            theme.palette.mode === 'light'
                ? '1px solid #E5E6EB'
                : '1px solid #394150',
        overflow: 'auto',
        background: (theme) => theme.backgroundColor.primary,
    },
    notificationCount: {
        display: 'flex',
        width: '24px',
        height: '24px',
        padding: '8px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '10px',
        borderRadius: '1000px',
        background: '#F75555',
    },
    content: {
        position: 'relative',
        backgroundColor: (theme) => theme.backgroundColor.secondary,
        height: isOnboarding ? 'auto' : '100vh',
        overflow: 'auto',
        paddingTop: isOnboarding ? '0' : '85px',
        width: isOnboarding ? '100%' : '100%',
        '&::-webkit-scrollbar': {
            width: '0.4rem',
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        },
        '&::-webkit-scrollbar-thumb': {
            margin: 1,
            borderRadius: '8px',
            backgroundColor: 'rgba(0,0,0,.1)',
        },
    },
    notificationMenuStyle: {
        display: 'flex',
        alignItems: 'center',
        flexShrink: 0,
        width: '300px',
        borderBottom: (theme) => `1px solid ${theme.borderColor.inputField}`,
        background: (theme) => theme.backgroundColor.primary,
        p: 1,
        '& ul': {
            height: '500px',
            overflowY: 'auto',
            p: 0,
            m: 0,
            width: '100%',
            '& li': {
                whiteSpace: 'nowrap', // Prevents text from wrapping to the next line
                overflow: 'hidden', // Hides any text that overflows the container
                textOverflow: 'ellipsis', // Displays an ellipsis (...) when text is clipped
                '&:hover': {
                    borderRadius: '12px',
                },
            },

            '&::-webkit-scrollbar': {
                width: '0.8rem',
            },
            '&::-webkit-scrollbar-track': {
                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            },
            '&::-webkit-scrollbar-thumb': {
                margin: 1,
                borderRadius: '8px',
                backgroundColor: 'rgba(0,0,0,.1)',
            },
        },
    },
});

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels,
);

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open, isScreenLowerThan1024 }) => ({
    width: isScreenLowerThan1024 ? 0 : drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme, isScreenLowerThan1024),
        '& .MuiDrawer-paper': closedMixin(theme, isScreenLowerThan1024),
    }),
}));

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme, isScreenLowerThan1024): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: isScreenLowerThan1024 ? 0 : `calc(${theme.spacing(7)} + 5px)`,
    [theme.breakpoints.up('sm')]: {
        width: isScreenLowerThan1024 ? 0 : `calc(${theme.spacing(8)} + 5px)`,
    },
});

// const menuItems = [
//     {
//         name: (
//             <Grid container>
//                 <Typography text={'This is a very long Notification'} />
//             </Grid>
//         ),
//         onClick: () => {},
//     },
//     {
//         name: (
//             <Grid container>
//                 <Typography text={'Notification 1'} />
//             </Grid>
//         ),
//         onClick: () => {},
//     },
//     {
//         name: (
//             <Grid container>
//                 <Typography text={'Notification 1'} />
//             </Grid>
//         ),
//         onClick: () => {},
//     },
//     {
//         name: (
//             <Grid container>
//                 <Typography text={'Notification 1'} />
//             </Grid>
//         ),
//         onClick: () => {},
//     },
//     {
//         name: (
//             <Grid container>
//                 <Typography text={'Notification 1'} />
//             </Grid>
//         ),
//         onClick: () => {},
//     },
//     {
//         name: (
//             <Grid container>
//                 <Typography text={'Notification 1'} />
//             </Grid>
//         ),
//         onClick: () => {},
//     },
//     {
//         name: (
//             <Grid container>
//                 <Typography text={'Notification 1'} />
//             </Grid>
//         ),
//         onClick: () => {},
//     },
//     {
//         name: (
//             <Grid container>
//                 <Typography text={'Notification 1'} />
//             </Grid>
//         ),
//         onClick: () => {},
//     },
//     {
//         name: (
//             <Grid container>
//                 <Typography text={'Notification 1'} />
//             </Grid>
//         ),
//         onClick: () => {},
//     },
//     // Add other menu items as needed
// ];

const App = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { logout, user } = useAuth();
    const { getLoggedInUserDetails } = useAuthService();
    const { getUserAssociatedCompany } = useCompanyService();
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    // const [notificationAnchorEl, setNotificationAnchorEl] = useState(null);
    const { themeMode, toggleTheme } = useThemeToggle();
    const isDarkMode = themeMode === 'dark' ? true : false;
    const [activeMenu, setActiveMenu] = useState('');

    const { token } = useSelector((state: RootState) => state.auth);

    const currentPath = PRIVATE_ROUTES.find((route) => {
        return route.path === window.location.pathname;
    })?.label;

    const isScreenLowerThan1024 = useMediaQuery('(max-width: 1025px)');
    // const isScreenLowerThan1167 = useMediaQuery('(max-width: 1300px)'); //Custom media query for options in header bar.

    const styles = makeStyles({
        isDrawerOpen: open,
        isScreenLowerThan1024,
        themeMode,
        isOnboarding: currentPath === 'onboarding',
    });

    useEffect(() => {
        if (token) {
            const { exp } = jose.decodeJwt(token);
            if (exp) {
                if (exp * 1000 < Date.now()) {
                    logout();
                    dispatch(LOGOUT());
                }
            }
        } else if (
            window.location.pathname !== '/signup' &&
            window.location.pathname !== '/login' &&
            window.location.pathname !== '/forgotpassword' &&
            !window.location.pathname.startsWith('/password-reset')
        ) {
            navigate('/login');
        }
    }, [token, dispatch, logout, navigate]);

    const getAuthMe = useCallback(() => {
        dispatch(GET_AUTH_ME({ getLoggedInUserDetails }));
    }, [dispatch, getLoggedInUserDetails]);

    useEffect(() => {
        if (
            token &&
            isObjectEmpty(user.data) &&
            window.location.pathname !== '/signup' &&
            window.location.pathname !== '/login'
        ) {
            getAuthMe();
        }
        // else {
        //     navigate('/login');
        // }
    }, [getAuthMe, token, user.data]);

    //TODO: Need to see its behavior
    // useEffect(() => {
    //     // If no data, redirect to the login page
    //     if (!user.data && window.location.pathname !== '/signup') {
    //         navigate('/login');
    //     } else {
    //     }
    // }, [navigate, user.data]);

    useEffect(() => {
        if (token && user?.data?.is_active) {
            dispatch(UPDATE_COMPANY_PENDING({ loading: true }));

            getUserAssociatedCompany()
                .then((data) => {
                    const companyData = data[0]; // Assuming the first company is the one you're interested in
                    dispatch(
                        UPDATE_COMPANY_SUCCESS({
                            data: {
                                id: companyData.id,
                                name: companyData.name,
                                timezone: {
                                    label: companyData.timezone,
                                    value: companyData.timezone,
                                },
                                industry: {
                                    label: companyData.industry_name,
                                    value: companyData.industry,
                                },
                                entity: {
                                    label: companyData.entity,
                                    value: companyData.entity,
                                },
                                trial_ends_at: companyData.trial_ends_at,
                                subscription_expires_at:
                                    companyData.subscription_expires_at,
                            },
                        }),
                    );
                    // After updating company data, check subscription and trial status
                    // checkSubscriptionAndNavigate(companyData);
                })

                .catch((err) => {
                    dispatch(UPDATE_COMPANY_ERROR({ err }));
                });
        }
    }, [getUserAssociatedCompany, dispatch, token, user?.data?.is_active]);

    // const checkSubscriptionAndNavigate = (companyData) => {
    //     const now = new Date();
    //     const trialEndsAt = companyData.trial_ends_at
    //         ? new Date(companyData.trial_ends_at)
    //         : null;
    //     const subscriptionExpiresAt = companyData.subscription_expires_at
    //         ? new Date(companyData.subscription_expires_at)
    //         : null;
    //     const subscriptionActive = companyData.subscription_active;

    //     // Navigate to /subscriptions if trial ended or subscription expired
    //     if (
    //         (trialEndsAt && trialEndsAt < now) ||
    //         (!subscriptionActive &&
    //             subscriptionExpiresAt &&
    //             subscriptionExpiresAt < now)
    //     ) {
    //         navigate('/subscriptions');
    //     }
    // };

    const handleDrawerToggle = () => {
        setOpen(!open);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleMenuClick = (e, option) => {
        if (option.path.startsWith('https')) {
            // If the path starts with 'http', it's a URL
            window.open(option.path, '_blank');
            return;
        }
        if (option.path === '/darkMode') {
            e.stopPropagation();
            return;
        }
        if (option.path === '/logout') {
            logout();
            return;
        } else {
            setActiveMenu(option.name);
            navigate(option.path);
        }
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const renderedPrivateRoutes = PRIVATE_ROUTES.map((route, index) => (
        <Route key={index} path={route.path} element={route.element} />
    ));

    const renderedPublicRoutes = PUBLIC_ROUTES.map((route, index) => (
        <Route key={index} path={route.path} element={route.element} />
    ));

    const handleDarkMode = (e) => {
        e.stopPropagation();
        toggleTheme(e);
    };

    const handleLogout = () => {
        logout();
    };

    // const handleNotificationClick = (event) => {
    //     setNotificationAnchorEl(event.currentTarget);
    // };

    // const handleNotificationClose = () => {
    //     setNotificationAnchorEl(null);
    // };

    return (
        <ThemeProvider theme={themeMode === 'light' ? lightTheme : darkTheme}>
            <CssBaseline />
            {user.data ? (
                <Fragment>
                    <Box
                        sx={{
                            display: 'flex',
                        }}
                        className="circler-container"
                    >
                        {currentPath !== 'onboarding' &&
                            currentPath !== 'Subscriptions' && (
                                <Box>
                                    <AppBar
                                        elevation={0}
                                        position="fixed"
                                        sx={{
                                            backgroundColor: (theme) =>
                                                theme.backgroundColor.primary,
                                            zIndex: (theme) =>
                                                theme.zIndex.drawer - 1,
                                        }}
                                    >
                                        <Toolbar
                                            disableGutters
                                            sx={styles.toolBar}
                                        >
                                            <Grid
                                                container
                                                spacing={2}
                                                justifyContent={'space-between'}
                                            >
                                                <Grid
                                                    item
                                                    sx={{
                                                        cursor: 'pointer',
                                                        color: '#121826',
                                                    }}
                                                    xs={8}
                                                    md={4}
                                                    lg={4}
                                                    display={'flex'}
                                                    alignItems={'center'}
                                                    justifyContent={
                                                        'flex-start'
                                                    }
                                                    ml={
                                                        isScreenLowerThan1024
                                                            ? 0
                                                            : 4
                                                    }
                                                >
                                                    {isScreenLowerThan1024 ? (
                                                        <Box
                                                            display={'flex'}
                                                            alignItems={
                                                                'center'
                                                            }
                                                        >
                                                            <IconButton
                                                                color="red !important"
                                                                onClick={
                                                                    handleDrawerToggle
                                                                }
                                                                sx={{
                                                                    p: '8px 0',
                                                                }}
                                                            >
                                                                {open ? (
                                                                    <Close
                                                                        sx={{
                                                                            color: '#121826',
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    <MenuIcon
                                                                        sx={{
                                                                            color: (
                                                                                theme,
                                                                            ) =>
                                                                                theme
                                                                                    .textColor
                                                                                    .dark,
                                                                        }}
                                                                    />
                                                                )}
                                                            </IconButton>
                                                            <Typography
                                                                variant="h6"
                                                                component="div"
                                                                customStyles={{
                                                                    fontSize:
                                                                        isScreenLowerThan1024
                                                                            ? '20px'
                                                                            : '24px',
                                                                    fontWeight: 700,
                                                                    textTransform:
                                                                        'capitalize',
                                                                }}
                                                                text={
                                                                    currentPath
                                                                }
                                                            />
                                                        </Box>
                                                    ) : (
                                                        <Typography
                                                            variant="h6"
                                                            component="div"
                                                            customStyles={{
                                                                fontSize:
                                                                    '24px',
                                                                fontWeight: 700,
                                                                textTransform:
                                                                    'capitalize',
                                                            }}
                                                            text={currentPath}
                                                        />
                                                    )}
                                                </Grid>

                                                <Grid
                                                    item
                                                    xs={
                                                        isScreenLowerThan1024
                                                            ? 4
                                                            : 2
                                                    }
                                                    md={7}
                                                    lg={7}
                                                    container
                                                    spacing={2}
                                                    display={'flex'}
                                                    justifyContent={'flex-end'}
                                                >
                                                    {isScreenLowerThan1024 ? (
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            container
                                                            p={'0 8px'}
                                                            display={'flex'}
                                                            justifyContent={
                                                                'flex-end'
                                                            }
                                                        >
                                                            <Grid
                                                                item
                                                                display={'flex'}
                                                                alignItems={
                                                                    'center'
                                                                }
                                                            >
                                                                {/* <IconButton>
                                                                {isDarkMode ? (
                                                                    <SearchDarkMode />
                                                                ) : (
                                                                    <Search />
                                                                )}
                                                            </IconButton> */}
                                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                                {/* <Badge
                                                                variant="dot"
                                                                color="primary"
                                                                sx={{
                                                                    '& .MuiBadge-badge':
                                                                        {
                                                                            color: '#fff',
                                                                            backgroundColor:
                                                                                '#F75555',
                                                                        },
                                                                }}
                                                            >
                                                                <IconButton
                                                                    sx={{
                                                                        p: 0,
                                                                    }}
                                                                    onClick={
                                                                        handleNotificationClick
                                                                    }
                                                                >
                                                                    {isDarkMode ? (
                                                                        <NotificationDarkMode label="Notification-dark-mode" />
                                                                    ) : (
                                                                        <Notification label="Notification" />
                                                                    )}
                                                                </IconButton>
                                                            </Badge> */}
                                                            </Grid>
                                                        </Grid>
                                                    ) : (
                                                        <Fragment>
                                                            <Grid
                                                                item
                                                                sx={{
                                                                    display:
                                                                        'flex',
                                                                    alignItems:
                                                                        'center',
                                                                }}
                                                            >
                                                                {/* {isScreenLowerThan1167 ? (
                                                                <IconButton>
                                                                    {isDarkMode ? (
                                                                        <SearchDarkMode />
                                                                    ) : (
                                                                        <Search />
                                                                    )}
                                                                </IconButton>
                                                            ) : (
                                                                <TextField
                                                                    id="search"
                                                                    label=""
                                                                    variant="outlined"
                                                                    placeholder="Search                              &#8984; K"
                                                                    InputProps={{
                                                                        startAdornment:
                                                                            (
                                                                                <InputAdornment position="start">
                                                                                    <InputSearch />
                                                                                </InputAdornment>
                                                                            ),
                                                                    }}
                                                                />
                                                            )} */}
                                                            </Grid>
                                                            {[
                                                                'Home',
                                                                'Transaction',
                                                                'History',
                                                            ].includes(
                                                                currentPath,
                                                            ) && (
                                                                <Grid
                                                                    item
                                                                    sx={{
                                                                        display:
                                                                            isScreenLowerThan1024
                                                                                ? 'none'
                                                                                : {
                                                                                      md: 'flex',
                                                                                  },
                                                                        alignItems:
                                                                            'center',
                                                                    }}
                                                                >
                                                                    <NonAccountantMode
                                                                        label={
                                                                            'Non-accountant Mode'
                                                                        }
                                                                    />
                                                                </Grid>
                                                            )}

                                                            {/* <Grid
                                                            item
                                                            sx={{
                                                                display:
                                                                    isScreenLowerThan1024
                                                                        ? 'none'
                                                                        : {
                                                                              md: 'flex',
                                                                          },
                                                                alignItems:
                                                                    'center',
                                                                paddingLeft: 5,
                                                                cursor: 'pointer',
                                                            }}
                                                        >
                                                            <Badge
                                                                badgeContent={
                                                                    ''
                                                                }
                                                                color="primary"
                                                                sx={{
                                                                    '& .MuiBadge-badge':
                                                                        {
                                                                            color: '#fff',
                                                                            backgroundColor:
                                                                                '#F75555',
                                                                            fontSize: 10,
                                                                            height: 13,
                                                                            minWidth: 5,
                                                                        },
                                                                }}
                                                                onClick={
                                                                    handleNotificationClick
                                                                }
                                                            >
                                                                {isDarkMode ? (
                                                                    <NotificationDarkMode label="Notification-dark-mode" />
                                                                ) : (
                                                                    <Notification label="Notification" />
                                                                )}
                                                            </Badge>
                                                        </Grid> */}
                                                            <Grid item>
                                                                <Box
                                                                    height={
                                                                        '100%'
                                                                    }
                                                                >
                                                                    <Divider
                                                                        variant="fullWidth"
                                                                        orientation="vertical"
                                                                        sx={{
                                                                            background:
                                                                                (
                                                                                    theme,
                                                                                ) =>
                                                                                    theme
                                                                                        .palette
                                                                                        .mode ===
                                                                                    'dark'
                                                                                        ? '#394150'
                                                                                        : '#E5E6EB',
                                                                            borderRightWidth:
                                                                                '1px',
                                                                        }}
                                                                    />
                                                                </Box>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                sx={{
                                                                    display:
                                                                        'flex',
                                                                    alignItems:
                                                                        'center',
                                                                    cursor: 'pointer',
                                                                }}
                                                            >
                                                                <Avatar
                                                                    alt={
                                                                        user
                                                                            .data
                                                                            .first_name
                                                                    }
                                                                    src={`https://app.circler.io/${user.data.photo_url}`}
                                                                    onClick={
                                                                        handleClick
                                                                    }
                                                                />
                                                            </Grid>
                                                        </Fragment>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Toolbar>
                                    </AppBar>

                                    {isScreenLowerThan1024 ? (
                                        <SwipeableFromLeftDrawer
                                            isDrawerOpen={open}
                                            activeMen={activeMenu}
                                            currentPath={currentPath}
                                            handleMenuClick={handleMenuClick}
                                            notificationStyle={
                                                styles.notificationCount
                                            }
                                            setOpen={setOpen}
                                            isScreenLowerThan1024={
                                                isScreenLowerThan1024
                                            }
                                            isDarkMode={isDarkMode}
                                        />
                                    ) : (
                                        <Drawer
                                            open={open}
                                            variant="permanent"
                                            PaperProps={{
                                                sx: styles.drawerPaper,
                                            }}
                                            isScreenLowerThan1024={
                                                isScreenLowerThan1024
                                            }
                                        >
                                            <Sidebar
                                                handleMenuClick={
                                                    handleMenuClick
                                                }
                                                isDrawerOpen={open}
                                                activeMenu={activeMenu}
                                                setIsDrawerOpen={setOpen}
                                                currentPath={currentPath}
                                                notificationStyle={
                                                    styles.notificationCount
                                                }
                                                isScreenLowerThan1024={
                                                    isScreenLowerThan1024
                                                }
                                            />
                                        </Drawer>
                                    )}
                                    {!isScreenLowerThan1024 &&
                                        (open ? (
                                            <IconButton
                                                color="primary"
                                                aria-label="open drawer"
                                                component="label"
                                                edge="start"
                                                onClick={handleDrawerClose}
                                                size="small"
                                                sx={{
                                                    position: 'fixed',
                                                    top: '28px',
                                                    zIndex: 1200,
                                                    left: '218px',
                                                    background: (theme) =>
                                                        theme.backgroundColor
                                                            .primary,
                                                    border: (theme) =>
                                                        `1px solid ${theme.borderColor.inputField}`,
                                                    color: '#6C727F',
                                                    padding: '2px',
                                                    '& :hover': {
                                                        background: (theme) =>
                                                            theme
                                                                .backgroundColor
                                                                .primary,
                                                        borderRadius: '50%',
                                                    },
                                                }}
                                            >
                                                <ChevronLeftIcon />
                                            </IconButton>
                                        ) : (
                                            <IconButton
                                                onClick={handleDrawerToggle}
                                                color="primary"
                                                aria-label="open drawer"
                                                component="label"
                                                edge="start"
                                                sx={{
                                                    position: 'fixed',
                                                    top: '28px',
                                                    left: '66px',
                                                    zIndex: 1200,
                                                    background: (theme) =>
                                                        theme.backgroundColor
                                                            .primary,
                                                    border: (theme) =>
                                                        `1px solid ${theme.borderColor.inputField}`,
                                                    color: '#6C727F',
                                                    padding: '2px',
                                                    '&:hover': {
                                                        background: (theme) =>
                                                            theme
                                                                .backgroundColor
                                                                .primary,
                                                        borderRadius: '50%',
                                                    },
                                                }}
                                            >
                                                <ChevronRightIcon />
                                            </IconButton>
                                        ))}
                                </Box>
                            )}

                        <Box component="main" sx={styles.content}>
                            <Routes>{renderedPrivateRoutes}</Routes>
                        </Box>
                    </Box>
                    <Menu
                        items={[
                            {
                                name: (
                                    <Grid container spacing={2}>
                                        <Grid item>
                                            {isDarkMode ? (
                                                <UserProfileDarkMode />
                                            ) : (
                                                <UserProfile />
                                            )}
                                        </Grid>
                                        <Grid item>
                                            <Typography
                                                text={'My Profile'}
                                                customStyles={{
                                                    fontSize: 14,
                                                    fontWeight: 500,
                                                    color: (theme) =>
                                                        theme.palette.mode ===
                                                        'dark'
                                                            ? '#F9FAFB'
                                                            : '#6C727F',
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                ),
                                onClick: () => {
                                    navigate('/profile');
                                    setActiveMenu('');
                                },
                            },
                            {
                                name: (
                                    <Grid
                                        container
                                        spacing={2}
                                        display={'flex'}
                                        alignItems={'center'}
                                        justifyContent={'space-between'}
                                    >
                                        <Grid item xs={2}>
                                            {isDarkMode ? (
                                                <DarkModeIcon />
                                            ) : (
                                                <DarkMode />
                                            )}
                                        </Grid>
                                        <Grid item xs={6} display={'flex'}>
                                            <Typography
                                                text={'Dark Mode'}
                                                customStyles={{
                                                    fontSize: 14,
                                                    fontWeight: 500,
                                                    color: (theme) =>
                                                        theme.palette.mode ===
                                                        'dark'
                                                            ? '#F9FAFB'
                                                            : '#6C727F',
                                                }}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={4}
                                            display={'flex'}
                                            justifyItems={'flex-start'}
                                        >
                                            <Switch
                                                onClick={handleDarkMode}
                                                checked={isDarkMode}
                                            />
                                        </Grid>
                                    </Grid>
                                ),
                                onClick: () => {},
                            },
                            {
                                name: (
                                    <Grid container spacing={2}>
                                        <Grid item>
                                            <Logout />
                                        </Grid>
                                        <Grid item>
                                            <Typography
                                                text={'Log out'}
                                                customStyles={{
                                                    color: (theme) =>
                                                        theme.textColor.warning,
                                                    fontSize: 14,
                                                    fontWeight: 500,
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                ),
                                onClick: () => handleLogout(),
                                showDivider: true,
                            },
                            {
                                name: (
                                    <Grid container>
                                        <Grid item>
                                            <Typography
                                                text={'Privacy Policy'}
                                                customStyles={{
                                                    fontSize: 14,
                                                    fontWeight: 500,
                                                    color: (theme) =>
                                                        theme.palette.mode ===
                                                        'dark'
                                                            ? '#F9FAFB'
                                                            : '#6C727F',
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                ),
                                onClick: () => {
                                    window.open(
                                        'https://www.termsfeed.com/live/c453ce2a-a7f6-4fd4-8956-efc53c6f2510',
                                        '_blank',
                                    );
                                },
                            },
                            {
                                name: (
                                    <Grid container>
                                        <Grid item>
                                            <Typography
                                                text={'Terms and Conditions'}
                                                customStyles={{
                                                    fontSize: 14,
                                                    fontWeight: 500,
                                                    color: (theme) =>
                                                        theme.palette.mode ===
                                                        'dark'
                                                            ? '#F9FAFB'
                                                            : '#6C727F',
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                ),
                                onClick: () => {
                                    window.open(
                                        'https://www.termsfeed.com/live/2c8418f9-830b-4917-95c9-1a30f68a8c7a',
                                        '_blank',
                                    );
                                },
                            },
                            {
                                name: (
                                    <Grid container>
                                        <Grid item>
                                            <Typography
                                                text={'About Us'}
                                                customStyles={{
                                                    fontSize: 14,
                                                    fontWeight: 500,
                                                    color: (theme) =>
                                                        theme.palette.mode ===
                                                        'dark'
                                                            ? '#F9FAFB'
                                                            : '#6C727F',
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                ),
                                onClick: () => {
                                    window.open(
                                        'https://circler.io/',
                                        '_blank',
                                    );
                                },
                            },
                        ]}
                        anchorEl={anchorEl}
                        handleClose={handleCloseMenu}
                        customStyles={{
                            width: '254px',
                            p: 1,
                        }}
                    />
                    {/* <Menu
                        anchorEl={notificationAnchorEl}
                        items={menuItems}
                        handleClose={handleNotificationClose}
                        customStyles={{
                            ...styles.notificationMenuStyle,
                        }}
                    /> */}
                </Fragment>
            ) : (
                <Routes>{renderedPublicRoutes}</Routes>
            )}
        </ThemeProvider>
    );
};

export default App;
