import React from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, Grid, IconButton } from '@mui/material';
import { Typography } from '../shared';
import { ReactComponent as UploadIcon } from '../assets/icons/UploadFiles.svg';
import { ReactComponent as Trash } from '../assets/icons/Trash.svg';
import { formatBytes } from '../utils/helpers';

const styles = {
    uploadContainer: {
        border: (theme) => `1px dashed ${theme.borderColor.inputField}`,
        borderRadius: '12px',
        height: '200px',
        overflow: 'auto',
        width: '100%',
    },
    fileUploadStyles: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        cursor: 'pointer',
    },
    fileListItem: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '8px',
        borderBottom: '1px solid #ccc',
    },
};

function UploadFile({
    onChange,
    name = '',
    label = '',
    progress = 0,
    error = false,
    helperText = '',
    loading = false,
    setSelectedFiles,
    selectedFiles,
    primaryText = '',
    required = false,
    secondaryText = '',
    allowedImages = true,
}) {
    const onDrop = (acceptedFiles) => {
        if (handleFiles) {
            handleFiles(acceptedFiles);
        } else {
            onChange({
                target: {
                    name,
                    value: allowedImages ? acceptedFiles[0] : acceptedFiles,
                },
            });
        }
    };

    const handleFiles = (acceptedFiles) => {
        setSelectedFiles(acceptedFiles);
        onChange({
            target: {
                name: `You have uploaded ${acceptedFiles.length} files}`,
                value: acceptedFiles,
            },
        });
    };

    const removeFile = (index) => {
        const updatedFiles = [...selectedFiles];
        updatedFiles.splice(index, 1);
        setSelectedFiles(updatedFiles);
        onChange({
            target: {
                name,
                value: updatedFiles,
            },
        });
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        ...(allowedImages && {
            accept: {
                'application/file': ['.jpeg', '.png', '.jpg', '.gif', '.pdf'],
            },
        }),
        ...(allowedImages && { maxFiles: 5 }), // Remove maxFiles limit
    });

    return (
        <Grid container item pb={1} justifyContent="center">
            <Box sx={styles.uploadContainer}>
                {selectedFiles.length ? (
                    <Grid container item>
                        {selectedFiles.map((file, index) => (
                            <React.Fragment key={index}>
                                <Grid item xs={8} sx={styles.fileListItem}>
                                    <Typography text={file.name} />
                                </Grid>
                                <Grid
                                    item
                                    xs={2}
                                    sx={{
                                        ...styles.fileListItem,
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Typography text={formatBytes(file.size)} />
                                </Grid>
                                <Grid
                                    item
                                    xs={2}
                                    sx={{
                                        ...styles.fileListItem,
                                        justifyContent: 'end',
                                    }}
                                >
                                    <IconButton
                                        onClick={() => removeFile(index)}
                                    >
                                        <Trash />
                                    </IconButton>
                                </Grid>
                            </React.Fragment>
                        ))}
                    </Grid>
                ) : (
                    <Grid container item {...getRootProps()} p={2}>
                        <Grid item xs={12}>
                            <input {...getInputProps()} />
                            <Box margin="auto" sx={styles.fileUploadStyles}>
                                <IconButton sx={{ pb: 2 }}>
                                    <UploadIcon alt="UploadIcon-button" />
                                </IconButton>
                                <Typography
                                    text={primaryText}
                                    customStyles={{
                                        fontSize: '14px',
                                        color: (theme) =>
                                            theme.palette.mode === 'dark'
                                                ? '#3381E2'
                                                : '#0061DB',
                                        fontWeight: 500,
                                    }}
                                />
                                <Typography
                                    text={secondaryText}
                                    customStyles={{
                                        fontSize: '12px',
                                        textAlign: 'right',
                                    }}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                )}
            </Box>
        </Grid>
    );
}

export default UploadFile;
