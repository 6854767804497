import { Badge, Divider, Grid } from '@mui/material';
import { ReactComponent as CirclerIcon } from '../../assets/icons/Icon.svg';
import { ReactComponent as CirclerIconDarkMode } from '../../assets/icons/CirclerIconDarkMode.svg';
import { DropDown, Typography } from '../../shared';
import { useCustomMediaQuery } from '../../hooks/useMediaQuery';

const styles = {
    chatHeading: {
        color: (theme) => theme.textColor.dark,
        fontWeight: 600,
    },
};

const options = [
    { value: 'Expense', label: 'Expense' },
    { value: 'Revenue', label: 'Revenue' },
    // Add more options as needed
];

export const ChatHeader = ({
    isDarkMode,
    setTransactionType,
    transactionType,
}) => {
    const isMobileScreen = useCustomMediaQuery('(max-width:600px)');
    const handleDropDownChange = (event) => {
        setTransactionType(event.target.value);
    };

    return (
        <Grid container spacing={1} width={'100%'}>
            <Grid
                item
                container
                xs={12}
                spacing={2}
                justifyContent={'space-between'}
            >
                <Grid
                    item
                    container
                    xs={isMobileScreen ? 12 : 6}
                    spacing={2}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                    }}
                >
                    <Grid item>
                        <Badge
                            overlap="circular"
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            variant="dot"
                            color="success"
                            sx={{
                                '& .MuiBadge-dot': {
                                    backgroundColor: '#0CAF60',
                                },
                            }} // Setting custom color
                        >
                            {isDarkMode ? (
                                <CirclerIconDarkMode />
                            ) : (
                                <CirclerIcon />
                            )}
                        </Badge>
                    </Grid>
                    <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography
                            text="Circler Bookkeeper"
                            customStyles={styles.chatHeading}
                        />
                    </Grid>
                </Grid>

                <Grid
                    item
                    xs={isMobileScreen ? 12 : 6}
                    display={'flex'}
                    justifyContent={isMobileScreen ? 'flex-start' : 'flex-end'}
                >
                    <DropDown
                        options={options}
                        value={transactionType}
                        onChange={handleDropDownChange}
                        id={'transaction-type'}
                        minWidth={'auto'}
                        placeHolder="Select an option"
                        customStyles={{
                            '& > :focus': { backgroundColor: 'white' },
                        }}
                    />
                </Grid>
            </Grid>

            <Grid item sx={{ width: '100%', mt: 1 }}>
                <Divider />
            </Grid>
        </Grid>
    );
};
