import React from 'react';
import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableSortLabel,
    Checkbox,
    TableBody,
    IconButton,
    Theme,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { omit } from 'lodash';
import { Typography } from './Typography';

const styles = {
    tableContainer: {
        width: '100%',
        background: (theme) => theme.backgroundColor.primary,
        overflow: 'auto',
        height: '600px',
        padding: '4px 24px 0px 24px',
        borderRadius: '12px',
        '& .MuiTableCell-root': {
            borderColor: (theme: Theme) =>
                theme.palette.mode === 'dark' ? '#394150' : '#E5E6EB',
            fontSize: '12px',
        },
        '&::-webkit-scrollbar': {
            width: '0.4rem',
            height: '0.4rem',
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        },
        '&::-webkit-scrollbar-thumb': {
            margin: 1,
            borderRadius: '8px',
            backgroundColor: (theme) => theme.primary.dark,
        },
    },
    headerTableCell: {
        p: '12px',
        background: (theme) => theme.backgroundColor.primary,
        borderBottom: (theme) =>
            theme.palette.mode === 'dark'
                ? '1px solid #394150'
                : '1px solid #E5E6EB',
        fontWeight: 500,
        fontSize: 14,
        color: '#6C727F',

        '&:last-child': {
            borderRadius: '0px 0px 0px 0px',
        },
    },
    nonSelectableHeaderTableCell: {
        py: 0,
        background: (theme) => theme.backgroundColor.primary,
        fontWeight: 600,
        padding: '10px',
        borderRadius: '0px 0px 0px 0px',
    },
    headerDividerCell: {
        padding: '6px 0',
        backgroundColor: 'transparent',
        borderBottom: 0,
    },
    headerCellLabel: {
        whiteSpace: 'nowrap',
    },
    headerCheckbox: {
        paddingLeft: 2,
        color: (theme: Theme) => theme.palette.primary.main,
    },
    checkbox: {
        color: (theme: Theme) => theme.palette.primary.main,
    },
    tableRow: {
        cursor: 'pointer',
    },
};

export const DataTable = ({
    headCells = [],
    data = [],
    sorting = {},
    sortOrder = 'asc',
    sortHandler = () => {},
    selected = {},
    setSelected = () => {},
    setAnchorEl = () => {},
    //TODO: Disabling all the selectable elements in DataTable as there is no information how to perform bulk op.
    isSelectable = false,
    canEdit,
    handleRowClick = () => {},
    isRowClickable = false,
    visibleColumns = headCells.map((headCell) => headCell.id),
    showColumn,
    actionIconPlacement,
    isMobileScreen = false,
    isLoading = false,
}) => {
    const handleSelectAllRows = (event) => {
        if (event.target.checked) {
            const selectedRows = {};
            data.forEach((cellData: Row) => {
                selectedRows[cellData.id] = true;
            });
            setSelected(selectedRows);
        } else setSelected({});
    };

    const handleSelectRow = (event: any, id: string) => {
        event.stopPropagation();
        if (event.target.checked) {
            setSelected({ ...selected, [id]: true });
        } else {
            setSelected(omit(selected, id));
        }
    };

    return (
        <TableContainer sx={styles.tableContainer}>
            <Table>
                <TableHead>
                    <TableRow>
                        {isSelectable && (
                            <TableCell
                                padding="checkbox"
                                sx={{
                                    ...styles.headerTableCell,
                                    borderRadius: '8px 0 0 8px',
                                }}
                            >
                                <Checkbox
                                    color="primary"
                                    indeterminate={
                                        Object.keys(selected).length > 0 &&
                                        data.length >
                                            Object.keys(selected).length
                                    }
                                    checked={
                                        data.length ===
                                        Object.keys(selected).length
                                    }
                                    onChange={handleSelectAllRows}
                                    sx={styles.headerCheckbox}
                                />
                            </TableCell>
                        )}
                        {headCells.map(
                            (headCell, index: number) =>
                                visibleColumns.includes(headCell.id) && (
                                    <TableCell
                                        key={headCell.id}
                                        sx={
                                            !isSelectable && index === 0
                                                ? {
                                                      ...styles.nonSelectableHeaderTableCell,
                                                      maxWidth:
                                                          headCell.maxWidth ||
                                                          'unset',
                                                  }
                                                : !canEdit &&
                                                  index === headCells.length - 1
                                                ? {
                                                      ...styles.headerTableCell,
                                                      textAlign:
                                                          headCell.textAlign,
                                                      borderRadius:
                                                          '0 8px 8px 0',
                                                  }
                                                : {
                                                      ...styles.headerTableCell,
                                                      maxWidth:
                                                          headCell.maxWidth ||
                                                          'unset',
                                                      textAlign:
                                                          headCell.textAlign,
                                                  }
                                        }
                                    >
                                        <TableSortLabel
                                            active={sorting[headCell.id]}
                                            direction={
                                                sorting[headCell.id]
                                                    ? sortOrder
                                                    : 'asc'
                                            }
                                            onClick={() =>
                                                sortHandler(headCell.id)
                                            }
                                            sx={styles.headerCellLabel}
                                            hideSortIcon={!headCell.sort}
                                        >
                                            <Typography
                                                text={headCell.label}
                                                customStyles={{
                                                    fontSize: 14,
                                                    fontWeight: 500,
                                                    color: (theme) =>
                                                        theme.palette.mode ===
                                                        'dark'
                                                            ? '#6C727F'
                                                            : '#4D5461',
                                                }}
                                            />
                                        </TableSortLabel>
                                    </TableCell>
                                ),
                        )}
                        {canEdit && (
                            <TableCell
                                sx={{
                                    ...styles.headerTableCell,
                                    borderRadius: '0 8px 8px 0',
                                }}
                            />
                        )}
                    </TableRow>
                    <TableRow>
                        <TableCell sx={styles.headerDividerCell} />
                        {headCells.map((cell) => (
                            <TableCell
                                key={`divider-cell-${cell.id}`}
                                sx={styles.headerDividerCell}
                            />
                        ))}
                        <TableCell sx={styles.headerDividerCell} />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {/* {true ? (
                        <Box display="flex" alignItems="center">
                            <Typography text={'Loading Records ...'} />
                            <CircularIndeterminate />
                        </Box>
                    ) : data.length > 0 ? (
                        data.map((row, index) => (
                            <TableRow
                                hover
                                role="checkbox"
                                key={`table-row-${row.id || index}`}
                                sx={isRowClickable ? styles.tableRow : {}}
                                selected={!!selected?.[row.id]}
                                onClick={() =>
                                    isRowClickable && handleRowClick(index)
                                }
                            >
                                {isSelectable && (
                                    <TableCell
                                        padding="none"
                                        sx={{ padding: '10px' }}
                                    >
                                        <Checkbox
                                            sx={styles.checkbox}
                                            checked={!!selected[row.id]}
                                            onClick={(event) =>
                                                handleSelectRow(event, row.id)
                                            }
                                        />
                                    </TableCell>
                                )}
                                {Object.entries(row).map(
                                    ([key, value], index) => {
                                        return (
                                            key !== 'id' &&
                                            visibleColumns.includes(key) && (
                                                <TableCell
                                                    key={`table-cell-${index}`}
                                                    padding={
                                                        headCells.find(
                                                            (headCell) =>
                                                                key ===
                                                                headCell.id,
                                                        )?.paddingVariant ||
                                                        'normal'
                                                    }
                                                    sx={{
                                                        maxWidth:
                                                            headCells.find(
                                                                (headCell) =>
                                                                    key ===
                                                                    headCell.id,
                                                            )?.maxWidth ||
                                                            'unset',
                                                        verticalAlign:
                                                            headCells.find(
                                                                (headCell) =>
                                                                    key ===
                                                                    headCell.id,
                                                            )?.verticalAlign,
                                                    }}
                                                >
                                                    {value}
                                                </TableCell>
                                            )
                                        );
                                    },
                                )}
                                {canEdit && (
                                    <TableCell
                                        padding="checkbox"
                                        sx={{
                                            verticalAlign: actionIconPlacement,
                                            p: '10px 0',
                                        }}
                                    >
                                        <IconButton
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                setAnchorEl(event, index);
                                            }}
                                        >
                                            <MoreVertIcon />
                                        </IconButton>
                                    </TableCell>
                                )}
                            </TableRow>
                        ))
                    ) : (
                        <TableRow>
                            <Box display="flex" alignItems="center">
                                <Typography text={'No Records'} />
                            </Box>
                        </TableRow>
                    )} */}
                    {data.length > 0 &&
                        data.map((row, index) => (
                            <TableRow
                                hover
                                role="checkbox"
                                key={`table-row-${row.id || index}`}
                                sx={isRowClickable ? styles.tableRow : {}}
                                selected={!!selected?.[row.id]}
                                onClick={() =>
                                    isRowClickable && handleRowClick(index)
                                }
                            >
                                {isSelectable && (
                                    <TableCell
                                        padding="none"
                                        sx={{ padding: '10px' }}
                                    >
                                        <Checkbox
                                            sx={styles.checkbox}
                                            checked={!!selected[row.id]}
                                            onClick={(event) =>
                                                handleSelectRow(event, row.id)
                                            }
                                        />
                                    </TableCell>
                                )}
                                {Object.entries(row).map(
                                    ([key, value], index) => {
                                        return (
                                            key !== 'id' &&
                                            visibleColumns.includes(key) && (
                                                <TableCell
                                                    key={`table-cell-${index}`}
                                                    padding={
                                                        headCells.find(
                                                            (headCell) =>
                                                                key ===
                                                                headCell.id,
                                                        )?.paddingVariant ||
                                                        'normal'
                                                    }
                                                    sx={{
                                                        maxWidth:
                                                            headCells.find(
                                                                (headCell) =>
                                                                    key ===
                                                                    headCell.id,
                                                            )?.maxWidth ||
                                                            'unset',
                                                        verticalAlign:
                                                            headCells.find(
                                                                (headCell) =>
                                                                    key ===
                                                                    headCell.id,
                                                            )?.verticalAlign,
                                                    }}
                                                >
                                                    {value}
                                                </TableCell>
                                            )
                                        );
                                    },
                                )}
                                {canEdit && (
                                    <TableCell
                                        padding="checkbox"
                                        sx={{
                                            verticalAlign: actionIconPlacement,
                                            p: '10px 0',
                                        }}
                                    >
                                        <IconButton
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                setAnchorEl(event, index);
                                            }}
                                        >
                                            <MoreVertIcon />
                                        </IconButton>
                                    </TableCell>
                                )}
                            </TableRow>
                        ))}
                </TableBody>
                {/* <TableFooter>
                    <TableRow>
                        <TableCell colSpan={8}>
                            {/* <TablePagination
                                count={data.length}
                                isMobileScreen={isMobileScreen}
                            />
                        </TableCell>
                    </TableRow>
                </TableFooter> */}
            </Table>
        </TableContainer>
    );
};
