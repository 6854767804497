import * as React from 'react';
import { TextField } from './TextField';
import { Autocomplete as ComboBox, Paper } from '@mui/material';

const CustomPaper = (props) => {
    return (
        <Paper
            elevation={3}
            sx={{
                boxShadow: (theme) => theme.boxShadow,
                backgroundColor: (theme: Theme) =>
                    theme.palette.mode === 'dark' ? '#212936' : '#fff',
                '& li': {
                    color: (theme) => theme.textColor.inputField,
                    fontSize: '16px',
                    fontWeight: 400,
                },
                backgroundImage: 'unset',
                border: (theme) => `1px solid ${theme.borderColor.inputField}`,
            }}
            {...props}
        />
    );
};

export const Autocomplete = (props) => {
    const filterOptions = (options, state) => {
        let newOptions = [];
        options.forEach((element) => {
            if (
                element.label
                    .replace(',', '')
                    .toLowerCase()
                    .includes(state.inputValue.toLowerCase())
            )
                newOptions.push(element);
        });
        return newOptions;
    };

    const isEqual = (option, value) => option.value === value.value;

    const {
        options,
        labelKey,
        label,
        width,
        placeholder,
        name,
        isSearchField,
        value,
        labelIcon,
        labelIconText,
        customStyle,
        customLabelStyles,
        disabled,
        onChange,
    } = props;

    return (
        <ComboBox
            disablePortal
            options={options}
            value={value}
            getOptionLabel={(option) => {
                return option[labelKey];
            }}
            sx={{
                width,
            }}
            label={label}
            name={name}
            filterOptions={filterOptions}
            PaperComponent={CustomPaper}
            disabled={disabled}
            onChange={(e, value) => onChange(e, value)}
            isOptionEqualToValue={isEqual}
            renderInput={(params) => (
                <TextField
                    label={label}
                    labelIcon={labelIcon}
                    placeholder={placeholder}
                    customStyle={customStyle}
                    labelIconText={labelIconText}
                    isSearchField={isSearchField}
                    customLabelStyles={customLabelStyles}
                    {...params}
                />
            )}
        />
    );
};
