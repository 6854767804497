import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Typography } from './Typography';
import { Box, Grid } from '@mui/material';
import { useCustomMediaQuery } from '../hooks/useMediaQuery';
import { AnimatedDots } from './AnimatedDots';

export default function GenericAccordion({
    panels,
    onChange,
    isLoading,
    expanded,
    setExpanded,
    customStyles,
    panelHeadingStyle,
    isMultiOpen = false,
    children,
    selectedPanel,
    setSelectedPanel,
}) {
    const isMobileScreen = useCustomMediaQuery('(max-width:600px)');

    const excludedPanels = [
        'Equity - Earnings',
        'Gross Profit',
        'Operating Income',
        'Net Income',
    ];
    const handleChange = (panel) => (event, isExpanded) => {
        const selectedPanel = panels.find((entry) => entry.id === panel);
        const isExcludedPanel = excludedPanels.includes(selectedPanel.heading);
        if (!isExcludedPanel) {
            onChange(selectedPanel);
            setExpanded(
                isMultiOpen ? togglePanel(panel) : isExpanded ? [panel] : [],
            );
        }
    };

    const togglePanel = (panel) => {
        const isPanelExpanded = expanded.includes(panel);
        if (isPanelExpanded) {
            return expanded.filter((p) => p !== panel);
        } else {
            return [...expanded, panel];
        }
    };

    return panels.map((panel) => {
        const isExcludedPanel = excludedPanels.includes(panel.heading);
        return (
            <Accordion
                key={panel.id}
                expanded={expanded.includes(panel.id)}
                onChange={handleChange(panel.id)}
                sx={{
                    backgroundColor: (theme) => theme.backgroundColor.primary,
                    backgroundImage: (theme) => 'unset',

                    '& .Mui-expanded': {
                        margin: '0 !important',
                    },
                    '&:after': { height: '0px' },
                    '&:before': { height: '0px' },
                    ...(isExcludedPanel && { ml: 3 }),
                }}
                elevation={0}
            >
                <AccordionSummary
                    expandIcon={!isExcludedPanel && <ExpandMoreIcon />}
                    aria-controls={`${panel.id}bh-content`}
                    id={`${panel.id}bh-header`}
                    sx={{
                        padding: 0,
                        flexDirection: isMultiOpen ? 'row-reverse' : 'unset',
                        ...customStyles,
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                        elevation={0}
                    >
                        <Typography
                            text={panel.heading}
                            sx={{
                                flexShrink: 0,
                                fontSize: 14,
                                color: (theme) => theme.textColor.primary,
                                ...panelHeadingStyle,
                            }}
                        />
                        {!isMobileScreen && panel.subHeading && (
                            <Typography
                                text={panel.subHeading}
                                customStyles={{
                                    fontSize: '14px',
                                    fontWeight: 600,
                                }}
                            />
                        )}
                    </Box>
                </AccordionSummary>
                <AccordionDetails
                    sx={{
                        padding: '0 5px',
                        ...customStyles,
                    }}
                >
                    {isLoading && selectedPanel?.id === panel.id ? (
                        <Typography
                            text={
                                <>
                                    Loading <AnimatedDots />
                                </>
                            }
                            sx={{
                                flexShrink: 0,
                                fontSize: 14,
                                color: (theme) => theme.textColor.primary,
                                textTransform: 'capitalize',
                            }}
                        />
                    ) : (
                        <Grid container>
                            {panel?.content ? (
                                Array.isArray(panel.content) ? (
                                    panel.content.map((dataItem) => (
                                        <Grid
                                            container
                                            key={dataItem.account_id}
                                            alignItems="center"
                                            justifyContent="space-between"
                                            sx={{
                                                width: '100%',
                                                marginBottom: '8px',
                                            }}
                                        >
                                            <Grid item>
                                                <Typography
                                                    text={dataItem.account_name
                                                        .split(' ')
                                                        .map(
                                                            (word) =>
                                                                word
                                                                    .charAt(0)
                                                                    .toUpperCase() +
                                                                word
                                                                    .slice(1)
                                                                    .toLowerCase(),
                                                        )
                                                        .join(' ')}
                                                    sx={{ fontWeight: '400' }}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Typography
                                                    text={new Intl.NumberFormat(
                                                        'en-US',
                                                        {
                                                            style: 'decimal',
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2,
                                                        },
                                                    ).format(dataItem.amount)}
                                                />
                                            </Grid>
                                        </Grid>
                                    ))
                                ) : (
                                    Object.entries(panel.content).map(
                                        ([category, accounts]) => {
                                            return (
                                                <Grid
                                                    container
                                                    key={category}
                                                    alignItems="center"
                                                    justifyContent="space-between"
                                                    sx={{
                                                        width: '100%',
                                                        marginBottom: '8px',
                                                    }}
                                                >
                                                    <Typography
                                                        variant="h6"
                                                        text={category}
                                                    />

                                                    {accounts.map((account) => (
                                                        <Grid
                                                            container
                                                            key={
                                                                account.account_id
                                                            }
                                                            alignItems="center"
                                                            justifyContent="space-between"
                                                            sx={{
                                                                width: '100%',
                                                                marginBottom:
                                                                    '8px',
                                                            }}
                                                        >
                                                            <Grid item>
                                                                <Typography
                                                                    text={account.account_name
                                                                        .split(
                                                                            ' ',
                                                                        )
                                                                        .map(
                                                                            (
                                                                                word,
                                                                            ) =>
                                                                                word
                                                                                    .charAt(
                                                                                        0,
                                                                                    )
                                                                                    .toUpperCase() +
                                                                                word
                                                                                    .slice(
                                                                                        1,
                                                                                    )
                                                                                    .toLowerCase(),
                                                                        )
                                                                        .join(
                                                                            ' ',
                                                                        )}
                                                                    sx={{
                                                                        fontWeight:
                                                                            '400',
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid item>
                                                                <Typography
                                                                    text={new Intl.NumberFormat(
                                                                        'en-US',
                                                                        {
                                                                            style: 'decimal',
                                                                            minimumFractionDigits: 2,
                                                                            maximumFractionDigits: 2,
                                                                        },
                                                                    ).format(
                                                                        account.amount,
                                                                    )}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            );
                                        },
                                    )
                                )
                            ) : (
                                <></>
                            )}
                        </Grid>
                    )}
                </AccordionDetails>
            </Accordion>
        );
    });
}
