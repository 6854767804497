import {
    Home,
    Login,
    Account,
    History,
    Dashboard,
    Transaction,
    Profile,
} from '../components';
import Onboarding from '../components/Onboarding';
import Subscriptions from '../components/Subscriptions';
import ConfirmNewPassword from '../components/ConfirmNewPassword';
import { ProtectedRoute } from '../components/ProtectedRoute';
import { SignUp } from '../components/Signup';
import { Navigate } from 'react-router-dom';
import ForgotPassword from '../components/ForgotPassword';

export const PRIVATE_ROUTES = [
    {
        path: '/onboarding',
        element: (
            <ProtectedRoute>
                <Onboarding />
            </ProtectedRoute>
        ),
        label: 'onboarding',
    },
    {
        path: '/home',
        element: (
            <ProtectedRoute>
                <Home />
            </ProtectedRoute>
        ),
        label: 'Home',
    },
    {
        path: '/',
        element: <Navigate replace to="/home" />,
        label: 'Redirect to Home',
    },
    {
        path: '/dashboard',
        element: (
            <ProtectedRoute>
                <Dashboard />
            </ProtectedRoute>
        ),
        label: 'Dashboard',
    },
    {
        path: '/account',
        element: (
            <ProtectedRoute>
                <Account />
            </ProtectedRoute>
        ),
        label: 'Chart of Accounts',
    },
    {
        path: '/transaction',
        element: (
            <ProtectedRoute>
                <Transaction />
            </ProtectedRoute>
        ),
        label: 'Transaction',
    },
    {
        path: '/history',
        element: (
            <ProtectedRoute>
                <History />
            </ProtectedRoute>
        ),
        label: 'History',
    },
    {
        path: '/profile',
        element: (
            <ProtectedRoute>
                <Profile />
            </ProtectedRoute>
        ),
        label: 'Profile',
    },
    {
        path: '/subscriptions',
        element: (
            <ProtectedRoute>
                <Subscriptions />
            </ProtectedRoute>
        ),
        label: 'Subscriptions',
    },
];

export const PUBLIC_ROUTES = [
    {
        path: '/login',
        element: <Login />,
    },
    {
        path: '/signup',
        element: <SignUp />,
    },
    {
        path: '/forgotPassword',
        element: <ForgotPassword />,
    },
    {
        path: '/password-reset/:uid/:token',
        element: <ConfirmNewPassword />,
    },
];
