import { ReactComponent as ProfileSettings } from '../assets/icons/PersonalSettings.svg';
import { ReactComponent as Security } from '../assets/icons/Security.svg';
import { ReactComponent as Industry } from '../assets/icons/Industry.svg';
import { ReactComponent as Information } from '../assets/icons/Information.svg';
import { ReactComponent as Advertisement } from '../assets/icons/Advertising.svg';
import { ReactComponent as ProfileSettingsDarkMode } from '../assets/icons/PersonalSettingsDarkMode.svg';
import { ReactComponent as SecurityDarkMode } from '../assets/icons/SecurityDarkMode.svg';
import { ReactComponent as IndustryDarkMode } from '../assets/icons/IndustryDarkMode.svg';
import { ReactComponent as InformationDarkMode } from '../assets/icons/InformationDarkMode.svg';
import { ReactComponent as ConsumerGoods } from '../assets/icons/ConsumerGoods.svg';
import { ReactComponent as ECommerce } from '../assets/icons/Ecommerce.svg';
import { ReactComponent as Enterprise } from '../assets/icons/Enterprise.svg';
import { ReactComponent as RealEstate } from '../assets/icons/RealEstate.svg';
import { ReactComponent as Manufacturing } from '../assets/icons/Manufacturing.svg';
import { ReactComponent as Marketplace } from '../assets/icons/Marketplace.svg';
import { ReactComponent as Other } from '../assets/icons/Other.svg';
import { ReactComponent as NotSure } from '../assets/icons/NotSure.svg';
import { ReactComponent as ProfessionalService } from '../assets/icons/ProfessionalService.svg';
import { ReactComponent as B2BSaas } from '../assets/icons/b2b.svg';
import { ReactComponent as B2C } from '../assets/icons/b2c.svg';

export const COLOR = {
    GREEN: '#FFFFFF',
};
export const DEFAULT_ICON_SIZE = 24;
export const DRAWER = {
    WIDTH: 240,
    MINI_SPACING: 7,
    MINI_SPACING_SMUP: 9,
};

export const DATE_OPTIONS = { year: 'numeric', month: 'long', day: 'numeric' };

export const PROFILE_SETTINGS = [
    {
        icon: <ProfileSettings />,
        darkModeIcon: <ProfileSettingsDarkMode />,
        name: 'Personal Settings',
    },
    {
        icon: <Information />,
        darkModeIcon: <InformationDarkMode />,
        name: 'Company Information',
    },
    {
        icon: <Industry />,
        darkModeIcon: <IndustryDarkMode />,
        name: 'Industry',
    },
    {
        icon: <Security />,
        darkModeIcon: <SecurityDarkMode />,
        name: 'Security',
    },
];

export const INDUSTRIES = [
    { name: 'Advertising', icon: <Advertisement /> },
    { name: 'Software (B2B/SaaS)', icon: <B2BSaas /> },
    { name: 'Software (B2C)', icon: <B2C /> },
    { name: 'Consumer goods', icon: <ConsumerGoods /> },
    { name: 'E-Commerce', icon: <ECommerce /> },
    { name: 'Marketplace', icon: <Marketplace /> },
    { name: 'Professional services', icon: <ProfessionalService /> },
    { name: 'Real Estate', icon: <RealEstate /> },
    { name: 'Manufacturing', icon: <Manufacturing /> },
    { name: 'Enterprise', icon: <Enterprise /> },
    // { name: 'Virtual goods', icon: <ProfessionalService /> },
    { name: 'Other', icon: <Other /> },
    { name: 'Not Sure', icon: <NotSure /> },
];

export const ENTITY_TYPES = [
    { label: 'LLC', value: 'LLC' },
    { label: 'Partnership', value: 'Partnership' },
    { label: 'S-Corporation', value: 'S-Corporation' },
    { label: 'Sole Proprietorship', value: 'Sole Proprietorship' },
    { label: 'C-Corporation', value: 'C-Corporation' },
    { label: 'None', value: 'None' },
];

export const HORIZONTAL_STACK_BAR_LABELS = [
    'Rent',
    'Software Subscription',
    'Employee Benefits',
    'Equipment',
    'Office Supplies',
    'Prototype Supplies',
];

export const CIRCLER_API_URL =
    `${process.env.REACT_APP_CIRCLER_API_URL}/api` ||
    'http://localhost:3001/api/';
