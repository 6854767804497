import React, { useCallback, useEffect, useState } from 'react';
import { Box, Divider, Grid, IconButton, Skeleton } from '@mui/material';
import { useSelector } from 'react-redux';
import { faker } from '@faker-js/faker';
import SwipeableViews from 'react-swipeable-views';
import { ReactComponent as ArrowUp } from '../assets/icons/ArrowUp.svg';
import { ReactComponent as ArrowDown } from '../assets/icons/ArrowDown.svg';
import CloseIcon from '@mui/icons-material/Close';
import { ReactComponent as DownArrow } from '../assets/icons/DownArrow.svg';
import { ReactComponent as NetIncome } from '../assets/icons/NetIncome.svg';
import { ReactComponent as Export } from '../assets/icons/Export.svg';
import { ReactComponent as CashBalanceIcon } from '../assets/icons/CashBalance.svg';
import { ReactComponent as CardBalanceIcon } from '../assets/icons/CardBalance.svg';
import { ReactComponent as LeftArrow } from '../assets/icons/LeftArrow.svg';
import { ReactComponent as RightArrow } from '../assets/icons/RightArrow.svg';
import { ReactComponent as ExcelIcon } from '../assets/icons/ExcelIcon.svg';
import { ReactComponent as CSV } from '../assets/icons/CSV.svg';
import { ReactComponent as LeftArrowDarkMode } from '../assets/icons/LeftArrowDarkMode.svg';
import { ReactComponent as RightArrowDarkMode } from '../assets/icons/RightArrowDarkMode.svg';
import { ReactComponent as Refresh } from '../assets/icons/Refresh.svg';
import { Typography, DateRangePicker, Button, Menu, DropDown } from '../shared';
import { downloadFile, formateDate, isObjectEmpty } from '../utils/helpers';
// import { Switch } from '../shared/Switch';
import { CURRENCIES } from '../utils/data';
import GenericAccordion from '../shared/Accordion';
import { useCustomMediaQuery } from '../hooks/useMediaQuery';
import CashInCashOutBar from './CashInCashOutChart';
import RevenueMonthChart from './RevenueMonthChart';
import { OperatingExpensesChart } from './OperatingExpensesChart';
import TopExpensesChart from './TopExpensesChart';
import CashPositionChart from './CashPositionChart';
import { useThemeToggle } from '../hooks/useThemeToggle';
import { useDashboardService } from '../store/services/dashboard.service';
import { useCompanyService } from '../store/services/company.service';
import { useReportService } from '../store/services/report.service';

const makeStyles = ({ isScreenLowerThan1024, balanceOption }) => ({
    root: {
        p: isScreenLowerThan1024 ? '40px 0 20px 20px' : '40px',
        overflow: 'auto',
    },
    cards: {
        p: isScreenLowerThan1024 ? '0px' : '56px 0 0 0',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: '12px',
        height: isScreenLowerThan1024 ? '326px' : '406px',
        backgroundColor: (theme) => theme.backgroundColor.primary,
    },
    chartCard: {
        borderRadius: '12px',
        // filter: 'blur(3px)',
        backgroundColor: (theme) => theme.backgroundColor.primary,
    },
    reportContainer: {
        p: '20px',
        m: isScreenLowerThan1024 ? '0' : '0 35px 0 25px',
        overflow: 'auto',
        borderRadius: '12px',
        justifyContent: 'space-between',
        backgroundColor: (theme) => theme.backgroundColor.primary,
    },
    reportDetailsContainer: {
        display: 'flex',
        padding: '0 12px',
        flexDirection: 'column',
        alignItems: 'flex-start',
        alignSelf: 'stretch',
        minHeight: '200px',
        borderRadius:
            balanceOption === 1
                ? '0px 12px 12px 12px'
                : balanceOption === 3
                ? '12px 0 12px 12px'
                : '12px 12px 12px 12px',
        background: (theme) => theme.backgroundColor.secondary,
    },
});

const labels = ['Jan 23', 'Feb 23', 'Mar 23', 'Apr 23'];

// const cashInData = {
//     labels,
//     datasets: [
//         {
//             label: 'Dataset 1',
//             data: labels.map(() =>
//                 faker.datatype.number({ min: -9000000, max: 0 }),
//             ),
//             backgroundColor: '#00C791',
//             barThickness: 12,
//             maxBarThickness: 12,
//             borderRadius: 5,
//         },
//         {
//             label: 'Dataset 2',
//             data: labels.map(() =>
//                 faker.datatype.number({ min: -9000000, max: 9000000 }),
//             ),
//             backgroundColor: '#8633FF',
//             barThickness: 12,
//             maxBarThickness: 12,
//             borderRadius: 5,
//         },
//         {
//             label: 'Dataset 3',
//             data: labels.map(() =>
//                 faker.datatype.number({ min: -9000000, max: 9000000 }),
//             ),
//             backgroundColor: '#D3D5DA',
//             barThickness: 12,
//             maxBarThickness: 12,
//             borderRadius: 5,
//         },
//     ],
// };

// const cashPositionBarData = {
//     labels: [...labels, 'Mar 23'],
//     datasets: [
//         {
//             label: 'Dataset 1',
//             data: [...labels, 'Mar 23'].map(() =>
//                 faker.datatype.number({ min: 0, max: 90000000 }),
//             ),
//             backgroundColor: '#66A0E9',
//             borderRadius: 4,
//             barThickness: 30,
//             maxBarThickness: 40,
//         },
//     ],
// };

const stackBarData = {
    labels,
    datasets: [
        {
            label: 'Dataset 1',
            data: labels.map(() =>
                faker.datatype.number({ min: 0, max: 5000 }),
            ),
            backgroundColor: '#8633FF',
            borderRadius: 40,
            barThickness: 30,
            maxBarThickness: 40,
        },
        {
            label: 'Dataset 2',
            data: labels.map(() =>
                faker.datatype.number({ min: 0, max: 50000 }),
            ),
            backgroundColor: '#FF1F6A',
            borderRadius: 4,
            barThickness: 30,
            maxBarThickness: 40,
        },
        {
            label: 'Dataset 3',
            data: labels.map(() =>
                faker.datatype.number({ min: 0, max: 50000 }),
            ),
            backgroundColor: '#00C791',
            borderRadius: 4,
            barThickness: 30,
            maxBarThickness: 40,
        },
        {
            label: 'Dataset 4',
            data: labels.map(() =>
                faker.datatype.number({ min: 0, max: 50000 }),
            ),
            backgroundColor: '#3381E2',
            borderRadius: 2,
            barThickness: 30,
            maxBarThickness: 40,
        },
    ],
};

// const revenueMonthData = {
//     labels,
//     datasets: [
//         {
//             label: 'Dataset 1',
//             data: labels.map(() =>
//                 faker.datatype.number({ min: -100000, max: 5000000 }),
//             ),
//             backgroundColor: '#0061DB',
//             barThickness: 12,
//             maxBarThickness: 12,
//         },
//         {
//             label: 'Dataset 2',
//             data: labels.map(() =>
//                 faker.datatype.number({ min: -500000, max: 5000000 }),
//             ),
//             backgroundColor: '#6DCFA0',
//             barThickness: 12,
//             maxBarThickness: 12,
//         },
//         {
//             label: 'Dataset 3',
//             data: labels.map(() =>
//                 faker.datatype.number({ min: -500000, max: 5000000 }),
//             ),
//             backgroundColor: '#8633FF',
//             barThickness: 12,
//             maxBarThickness: 12,
//         },
//     ],
// };

// loading data
const cashInsightItems = [
    {
        id: 1,
        title: 'vs. Apr 23',
        content: '$184,234',
    },
    {
        id: 2,
        title: 'vs. Mar 23',
        content: '$244,734',
    },
];

// TODO: Need to break this component into smaller components
export const Dashboard = () => {
    const {
        getInsightsView,
        getDashboardView,
        getNetIncomeView,
        getDashboardSecondView,
        getOperatingExpenseMonthsView,
    } = useDashboardService();

    const {
        getBalanceSheetDetail,
        getCashActivityDetail,
        getProfitLossDetail,
        getProfitLossSummary,
        getBalanceSheetSummary,
        getCashActivitySummary,
        downloadBSReportCSV,
        downloadCashReportCSV,
        downloadProfitLossCSV,
        isLoading: isReportLoading,
    } = useReportService();

    const { getCurrencyList } = useCompanyService();

    const [cashDetail, setCashDetail] = useState({
        card_balance: 0,
        cash_balance: 0,
        cash_in_out: [],
        cash_position: [],
    });

    const [revenueDetail, setRevenueDetail] = useState({
        revenue_months: [],
        top_expenses_this_month: [],
    });
    const [isLoading, setIsLoading] = useState(true);
    const [cashInsightItems, setCashInsightItems] = useState([]);
    const [operatingExpenses, setOperatingExpenses] = useState({
        operating_expenses_months: [],
    });
    console.log('🚀 ~ Dashboard ~ operatingExpenses:', operatingExpenses);

    const [netIncome, setNetIncome] = useState(0);
    const { data: company } = useSelector((state) => state.company);
    const [expanded, setExpanded] = useState([]);
    const [dateRange, setDateRange] = useState({
        startDate: `${formateDate(new Date(), 'YYYY-MM-DD')}`,
        endDate: `${formateDate(new Date(), 'YYYY-MM-DD')}`,
    });
    const [summary, setSummary] = useState();
    const [selectedPanel, setSelectedPanel] = useState();
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(0);
    // const [isSwitchOn, setIsSwitchOn] = useState(false);
    const [balanceOption, setBalanceOption] = useState(1);
    const [currencies, setCurrencies] = useState([]);
    const [currency, setCurrency] = useState('USD');
    const [isDateRangeOpen, setIsDateRangeOpen] = useState(false);
    const isScreenLowerThan1024 = useCustomMediaQuery('(max-width: 1025px)');
    const isScreenLowerThan1230 = useCustomMediaQuery('(max-width: 1230px)');
    const isMobileScreen = useCustomMediaQuery('(max-width:600px)');
    const styles = makeStyles({ isScreenLowerThan1024, balanceOption });
    const { themeMode } = useThemeToggle();
    const toggle = () => setIsDateRangeOpen(!isDateRangeOpen);
    const isDarkMode = themeMode === 'dark';

    const onChangeDateRange = (dateRange) => {
        setDateRange({
            startDate: `${formateDate(dateRange.startDate, 'YYYY-MM-DD')}`,
            endDate: `${formateDate(dateRange.endDate, 'YYYY-MM-DD')}`,
        });
        setIsDateRangeOpen(!isDateRangeOpen);
    };

    //TODO: Need to ask Yun if we need DD
    // const handleChange = (event) => {
    //     setSelectedCashValue(event.target.value);
    // };

    const handleBalanceOptionChange = (selectedBalanceOption) => {
        setExpanded([]);
        // setIsSwitchOn(false);
        setBalanceOption(selectedBalanceOption);
    };

    // const handleSwitchToggle = (isExpanded) => {
    //     setIsSwitchOn(!isSwitchOn);

    //     setExpanded(
    //         isExpanded ? Object.keys(summary).map((key) => `panel${key}`) : [],
    //     );
    // };

    const handlePrevClick = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex > 0 ? prevIndex - 1 : cashInsightItems.length - 1,
        );
    };

    const handleNextClick = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex < cashInsightItems.length - 1 ? prevIndex + 1 : 0,
        );
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleCurrencyChange = (e, value) => {
        setCurrency(e.target.value);
    };

    const handleAccordionChange = (selectedPanel) => {
        setSelectedPanel(selectedPanel);
        switch (balanceOption) {
            case 1:
                getCashActivityDetail(
                    company.id,
                    currency,
                    dateRange.startDate,
                    dateRange.endDate,
                    selectedPanel.heading,
                ).then((res) => {
                    setSummary((prevSummary) => {
                        return {
                            ...prevSummary,
                            [selectedPanel.heading]: {
                                ...prevSummary[selectedPanel.heading],
                                additionalProps: res,
                            },
                        };
                    });
                });
                break;
            case 2:
                getProfitLossDetail(
                    company.id,
                    currency,
                    dateRange.startDate,
                    dateRange.endDate,
                    selectedPanel.heading,
                ).then((res) => {
                    setSummary((prevSummary) => {
                        return {
                            ...prevSummary,
                            [selectedPanel.heading]: {
                                ...prevSummary[selectedPanel.heading],
                                additionalProps:
                                    res[selectedPanel.heading.toLowerCase()],
                            },
                        };
                    });
                });
                break;

            case 3:
                getBalanceSheetDetail(
                    company.id,
                    currency,
                    dateRange.endDate,
                    selectedPanel.heading,
                ).then((res) => {
                    setSummary((prevSummary) => {
                        return {
                            ...prevSummary,
                            [selectedPanel.heading]: {
                                ...prevSummary[selectedPanel.heading],
                                additionalProps: {
                                    ...res[selectedPanel.heading],
                                },
                            },
                        };
                    });
                });
                break;

            default:
                break;
        }
    };

    const transformSummary = (originalData) => {
        const transformedData = {};

        Object.keys(originalData).forEach((key) => {
            transformedData[key] = {
                amount: +originalData[key],
                content: <></>,
            };
        });
        return transformedData;
    };

    const getSummaryReport = useCallback(
        async (balanceOption) => {
            if (company) {
                switch (balanceOption) {
                    case 1: {
                        const result = await getCashActivitySummary(
                            company.id,
                            currency,
                            dateRange.endDate,
                            dateRange.startDate,
                        );

                        const transformedData = transformSummary(result);
                        setSummary(transformedData);
                        break;
                    }
                    case 2: {
                        const result = await getProfitLossSummary(
                            company.id,
                            currency,
                            dateRange.endDate,
                            dateRange.startDate,
                        );

                        const transformedData = transformSummary(result);
                        setSummary(transformedData);
                        break;
                    }
                    case 3: {
                        const result = await getBalanceSheetSummary(
                            company.id,
                            currency,
                            dateRange.endDate,
                        );
                        const transformedData = transformSummary(result);
                        setSummary(transformedData);
                        break;
                    }
                    default:
                        break;
                }
            }
        },
        [
            currency,
            company,
            dateRange.startDate,
            dateRange.endDate,
            getProfitLossSummary,
            getBalanceSheetSummary,
            getCashActivitySummary,
        ],
    );

    const handleDownLoadCSV = () => {
        switch (balanceOption) {
            case 1:
                downloadCashReportCSV(
                    company.id,
                    currency,
                    dateRange.startDate,
                    dateRange.endDate,
                ).then((res) => {
                    downloadFile(res, 'CashActivityReport');
                });
                break;
            case 2:
                downloadProfitLossCSV(
                    company.id,
                    currency,
                    dateRange.startDate,
                    dateRange.endDate,
                ).then((res) => {
                    downloadFile(res, 'ProfilLoss');
                });
                break;

            case 3:
                downloadBSReportCSV(
                    company.id,
                    currency,
                    dateRange.startDate,
                    dateRange.endDate,
                ).then((res) => {
                    downloadFile(res, 'BalanceSheet');
                });
                break;

            default:
                break;
        }
    };

    useEffect(() => {
        console.log('Attempting to fetch data');
        setIsLoading(true);
        if (company?.id)
            Promise.all([
                getInsightsView(),
                getDashboardView(company.id, currency),
                getDashboardSecondView(company.id, currency),
                getNetIncomeView(company.id, currency),
                getCurrencyList(company.id),
                getOperatingExpenseMonthsView(company.id, currency),
            ])
                .then(
                    ([
                        insights,
                        dashboard,
                        secondDashboardView,
                        netIncome,
                        currencies,
                        operatingExpenses,
                    ]) => {
                        setCashInsightItems(insights);
                        setCashDetail(dashboard);
                        setRevenueDetail(secondDashboardView);
                        setNetIncome(netIncome.net_income);
                        const matchedCurrencies = CURRENCIES.filter(
                            (currency) =>
                                currencies.code.includes(currency.value),
                        );
                        setCurrencies(matchedCurrencies);
                        debugger;
                        // console.log(
                        //     'Received operating expenses data: ',
                        //     operatingExpenses,
                        // );
                        // const transformedData = stackBarDatas(
                        //     operatingExpenses['operating_expenses_months'],
                        // );
                        setOperatingExpenses(operatingExpenses);
                        setIsLoading(false);
                    },
                )
                .catch((err) => {
                    setIsLoading(false);
                    console.error(err);
                });
    }, [
        currency,
        company?.id,
        getCurrencyList,
        getDashboardView,
        getNetIncomeView,
        getDashboardSecondView,
        getOperatingExpenseMonthsView,
    ]);

    useEffect(() => {
        getSummaryReport(balanceOption);
    }, [balanceOption, getSummaryReport]);

    // useEffect(() => {
    //     getInsightsView().then((insights) => setCashInsightItems(insights));
    // }, [getInsightsView]);

    return (
        <Grid container p={2}>
            <Grid item container sx={styles.root} spacing={4}>
                <Grid
                    container
                    item
                    xs={12}
                    p={'0 !important'}
                    display={'flex'}
                    justifyContent={'flex-end'}
                >
                    <Grid item display={'flex'} justifyContent={'flex-end'}>
                        <DropDown
                            value={currency}
                            options={currencies}
                            label={'Currency'}
                            onChange={handleCurrencyChange}
                            placeHolder="Select an option"
                            customStyles={{}}
                        />
                    </Grid>
                    {/* <Grid item>
                        <Button
                            text=""
                            customStyles={{
                                backgroundColor: '#0061DB',
                                color: (theme) => theme.textColor.white,
                                p: '12px 20px',
                                fontSize: '16px',
                                fontWeight: 500,
                                height: '48px',
                                '& .MuiButton-startIcon': {
                                    mr: '0px',
                                },
                            }}
                            startIcon={<Refresh />}
                        />
                    </Grid> */}
                </Grid>

                {isMobileScreen ? (
                    <>
                        <SwipeableViews enableMouseEvents>
                            <Grid
                                item
                                sm={6}
                                xs={12}
                                md={4}
                                lg={4}
                                sx={{ m: '24px 0 24px 30px' }}
                            >
                                <Grid
                                    container
                                    item
                                    sx={{ ...styles.cards }}
                                    spacing={2}
                                >
                                    <Grid item>
                                        <CashBalanceIcon
                                            width={
                                                isScreenLowerThan1024
                                                    ? '120px'
                                                    : '140px'
                                            }
                                            height={
                                                isScreenLowerThan1024
                                                    ? '120px'
                                                    : '140px'
                                            }
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography
                                            text="Cash Balance"
                                            customStyles={{
                                                fontWeight: 500,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        {isLoading ? (
                                            <Skeleton width={140} height={50} />
                                        ) : (
                                            <Typography
                                                text={cashDetail.cash_balance}
                                                customStyles={{
                                                    fontWeight: 700,
                                                    fontSize: 32,
                                                }}
                                            />
                                        )}
                                    </Grid>
                                    <Grid
                                        item
                                        container
                                        spacing={2}
                                        justifyContent={'space-around'}
                                    >
                                        <Grid
                                            item
                                            display={'flex'}
                                            justifyContent={'center'}
                                        >
                                            <ArrowUp /> &nbsp;
                                            <Typography
                                                text="23% compared to May 2023"
                                                customStyles={{
                                                    fontWeight: 400,
                                                    color: (theme) =>
                                                        theme.textColor.primary,
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                sm={6}
                                xs={12}
                                md={4}
                                lg={4}
                                sx={{ m: '24px 0 24px 30px' }}
                            >
                                <Grid
                                    container
                                    item
                                    sx={styles.cards}
                                    spacing={2}
                                >
                                    <Grid item>
                                        <CardBalanceIcon
                                            width={
                                                isScreenLowerThan1024
                                                    ? '120px'
                                                    : '140px'
                                            }
                                            height={
                                                isScreenLowerThan1024
                                                    ? '120px'
                                                    : '140px'
                                            }
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography
                                            text="Card Balance"
                                            customStyles={{
                                                fontWeight: 500,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        {isLoading ? (
                                            <Skeleton width={140} height={50} />
                                        ) : (
                                            <Typography
                                                text={cashDetail.card_balance}
                                                customStyles={{
                                                    fontWeight: 700,
                                                    fontSize: 32,
                                                }}
                                            />
                                        )}
                                    </Grid>
                                    <Grid
                                        item
                                        container
                                        spacing={2}
                                        justifyContent={'space-around'}
                                    >
                                        <Grid
                                            item
                                            display={'flex'}
                                            justifyContent={'center'}
                                        >
                                            {/* <ArrowDown /> &nbsp;
                                            <Typography
                                                text="-12% compared to May 2023"
                                                customStyles={{
                                                    fontWeight: 400,
                                                    color: (theme) =>
                                                        theme.textColor.primary,
                                                }}
                                            /> */}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                sm={6}
                                xs={12}
                                md={4}
                                lg={4}
                                sx={{ m: '24px 0 24px 30px' }}
                            >
                                <Grid
                                    container
                                    item
                                    sx={styles.chartCard}
                                    spacing={2}
                                    justifyContent={'center'}
                                    px={2.5}
                                    height={
                                        isScreenLowerThan1024
                                            ? '326px'
                                            : '406px'
                                    }
                                >
                                    <Grid item>
                                        <Typography
                                            text={'Cash In, Cash Out'}
                                            customStyles={{
                                                fontWeight: 500,
                                            }}
                                        />
                                    </Grid>
                                    {isLoading ? (
                                        <>
                                            <Grid item xs={12}>
                                                <Skeleton
                                                    width={'100%'}
                                                    height={10}
                                                />
                                                <Skeleton
                                                    width={'100%'}
                                                    height={10}
                                                />
                                                <Skeleton
                                                    width={'100%'}
                                                    height={10}
                                                />
                                                <Skeleton
                                                    width={'100%'}
                                                    height={10}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Skeleton
                                                    width={'100%'}
                                                    height={10}
                                                />
                                                <Skeleton
                                                    width={'100%'}
                                                    height={10}
                                                />
                                                <Skeleton
                                                    width={'100%'}
                                                    height={10}
                                                />
                                                <Skeleton
                                                    width={'100%'}
                                                    height={10}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Skeleton
                                                    width={'100%'}
                                                    height={10}
                                                />
                                                <Skeleton
                                                    width={'100%'}
                                                    height={10}
                                                />
                                                <Skeleton
                                                    width={'100%'}
                                                    height={10}
                                                />
                                                <Skeleton
                                                    width={'100%'}
                                                    height={10}
                                                />
                                            </Grid>
                                        </>
                                    ) : (
                                        <CashInCashOutBar
                                            data={{
                                                labels: cashDetail.cash_in_out.map(
                                                    (cash) => cash.month,
                                                ),
                                                datasets:
                                                    cashDetail.cash_in_out.map(
                                                        (cashInOut) => {
                                                            return {
                                                                label: '',
                                                                data: [
                                                                    cashInOut.cash_in,
                                                                    cashInOut.cash_out,
                                                                ],
                                                                backgroundColor:
                                                                    '#00C791',
                                                                barThickness: 12,
                                                                maxBarThickness: 12,
                                                                borderRadius: 5,
                                                            };
                                                        },
                                                    ),
                                            }}
                                            isDarkMode={themeMode === 'dark'}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        </SwipeableViews>
                        <SwipeableViews enableMouseEvents>
                            <Grid
                                item
                                sm={6}
                                xs={12}
                                md={4}
                                lg={4}
                                sx={{ m: '24px 0 24px 30px' }}
                            >
                                <Grid
                                    container
                                    item
                                    sx={styles.chartCard}
                                    spacing={2}
                                    px={2.5}
                                    height={
                                        isScreenLowerThan1024
                                            ? '326px'
                                            : '406px'
                                    }
                                    justifyContent={'center'}
                                >
                                    <Grid item>
                                        <Typography
                                            text={'Revenue'}
                                            customStyles={{
                                                fontWeight: 500,
                                            }}
                                        />
                                    </Grid>
                                    {isLoading ? (
                                        <>
                                            <Grid item xs={12}>
                                                <Skeleton
                                                    width={'100%'}
                                                    height={10}
                                                />
                                                <Skeleton
                                                    width={'100%'}
                                                    height={10}
                                                />
                                                <Skeleton
                                                    width={'100%'}
                                                    height={10}
                                                />
                                                <Skeleton
                                                    width={'100%'}
                                                    height={10}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Skeleton
                                                    width={'100%'}
                                                    height={10}
                                                />
                                                <Skeleton
                                                    width={'100%'}
                                                    height={10}
                                                />
                                                <Skeleton
                                                    width={'100%'}
                                                    height={10}
                                                />
                                                <Skeleton
                                                    width={'100%'}
                                                    height={10}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Skeleton
                                                    width={'100%'}
                                                    height={10}
                                                />
                                                <Skeleton
                                                    width={'100%'}
                                                    height={10}
                                                />
                                                <Skeleton
                                                    width={'100%'}
                                                    height={10}
                                                />
                                                <Skeleton
                                                    width={'100%'}
                                                    height={10}
                                                />
                                            </Grid>
                                        </>
                                    ) : (
                                        <RevenueMonthChart
                                            data={{
                                                labels: revenueDetail.revenue_months.map(
                                                    (cash) => cash.month,
                                                ),
                                                datasets:
                                                    revenueDetail.revenue_months.map(
                                                        (revenue) => {
                                                            return {
                                                                label: '',
                                                                data: [
                                                                    revenue.revenue,
                                                                    revenue.cost_of_revenue,
                                                                ],
                                                                backgroundColor:
                                                                    '#0061DB',
                                                                barThickness: 12,
                                                                maxBarThickness: 12,
                                                            };
                                                        },
                                                    ),
                                            }}
                                            isDarkMode={themeMode === 'dark'}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                sm={6}
                                xs={12}
                                md={4}
                                lg={4}
                                sx={{ m: '24px 0 24px 30px' }}
                            >
                                <Grid
                                    container
                                    item
                                    sx={styles.cards}
                                    spacing={2}
                                    justifyContent={'center'}
                                >
                                    <Grid item>
                                        <NetIncome
                                            width={
                                                isScreenLowerThan1024
                                                    ? '120px'
                                                    : '200px'
                                            }
                                            height={
                                                isScreenLowerThan1024
                                                    ? '120px'
                                                    : '200px'
                                            }
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography
                                            p={1}
                                            text="Net Income for This Year"
                                            customStyles={{
                                                fontWeight: 1000,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        {isLoading ? (
                                            <Skeleton height={50} width={250} />
                                        ) : (
                                            <Typography
                                                text={netIncome}
                                                customStyles={{
                                                    fontWeight: 700,
                                                    fontSize: 32,
                                                }}
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                sm={6}
                                xs={12}
                                md={4}
                                lg={4}
                                sx={{ m: '24px 0 24px 30px' }}
                            >
                                <Grid
                                    container
                                    item
                                    sx={styles.chartCard}
                                    spacing={2}
                                    px={2.5}
                                    height={
                                        isScreenLowerThan1024
                                            ? '326px'
                                            : '406px'
                                    }
                                    justifyContent={'center'}
                                >
                                    <Grid item>
                                        <Typography
                                            text={'Operating Expenses'}
                                            customStyles={{
                                                fontWeight: 500,
                                            }}
                                        />
                                    </Grid>
                                    {isLoading ? (
                                        <>
                                            <Grid item xs={12}>
                                                <Skeleton
                                                    width={'100%'}
                                                    height={10}
                                                />
                                                <Skeleton
                                                    width={'100%'}
                                                    height={10}
                                                />
                                                <Skeleton
                                                    width={'100%'}
                                                    height={10}
                                                />
                                                <Skeleton
                                                    width={'100%'}
                                                    height={10}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Skeleton
                                                    width={'100%'}
                                                    height={10}
                                                />
                                                <Skeleton
                                                    width={'100%'}
                                                    height={10}
                                                />
                                                <Skeleton
                                                    width={'100%'}
                                                    height={10}
                                                />
                                                <Skeleton
                                                    width={'100%'}
                                                    height={10}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Skeleton
                                                    width={'100%'}
                                                    height={10}
                                                />
                                                <Skeleton
                                                    width={'100%'}
                                                    height={10}
                                                />
                                                <Skeleton
                                                    width={'100%'}
                                                    height={10}
                                                />
                                                <Skeleton
                                                    width={'100%'}
                                                    height={10}
                                                />
                                            </Grid>
                                        </>
                                    ) : (
                                        <OperatingExpensesChart
                                            data={operatingExpenses}
                                            isDarkMode={themeMode === 'dark'}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        </SwipeableViews>
                        <SwipeableViews enableMouseEvents>
                            <Grid
                                item
                                sm={6}
                                xs={12}
                                md={4}
                                lg={4}
                                sx={{ m: '24px 0 24px 30px' }}
                            >
                                <Grid
                                    container
                                    item
                                    sx={styles.chartCard}
                                    spacing={2}
                                    height={
                                        isScreenLowerThan1024
                                            ? '326px'
                                            : '406px'
                                    }
                                    justifyContent={'center'}
                                    px={2.5}
                                    py={'0 !important'}
                                >
                                    <Grid item>
                                        <Typography
                                            text={'Top Expenses This Month'}
                                            customStyles={{ fontWeight: 500 }}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        width={'100%'}
                                        p={'0 !important'}
                                    >
                                        {isLoading ? (
                                            <Grid container item spacing={2}>
                                                <Grid item xs={12}>
                                                    <Skeleton
                                                        width={'100%'}
                                                        height={10}
                                                    />
                                                    <Skeleton
                                                        width={'100%'}
                                                        height={10}
                                                    />
                                                    <Skeleton
                                                        width={'100%'}
                                                        height={10}
                                                    />
                                                    <Skeleton
                                                        width={'100%'}
                                                        height={10}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Skeleton
                                                        width={'100%'}
                                                        height={10}
                                                    />
                                                    <Skeleton
                                                        width={'100%'}
                                                        height={10}
                                                    />
                                                    <Skeleton
                                                        width={'100%'}
                                                        height={10}
                                                    />
                                                    <Skeleton
                                                        width={'100%'}
                                                        height={10}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Skeleton
                                                        width={'100%'}
                                                        height={10}
                                                    />
                                                    <Skeleton
                                                        width={'100%'}
                                                        height={10}
                                                    />
                                                    <Skeleton
                                                        width={'100%'}
                                                        height={10}
                                                    />
                                                    <Skeleton
                                                        width={'100%'}
                                                        height={10}
                                                    />
                                                </Grid>
                                            </Grid>
                                        ) : (
                                            <TopExpensesChart
                                                topExpensesData={
                                                    revenueDetail.top_expenses_this_month
                                                }
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                sm={6}
                                xs={12}
                                md={4}
                                lg={4}
                                sx={{ m: '24px 0 24px 30px' }}
                            >
                                <Grid
                                    container
                                    item
                                    sx={styles.chartCard}
                                    spacing={2}
                                    height={
                                        isScreenLowerThan1024
                                            ? '326px'
                                            : '406px'
                                    }
                                    justifyContent={'center'}
                                    px={2.5}
                                    // alignItems={'center'}
                                    py={'0 !important'}
                                >
                                    <Grid
                                        item
                                        alignItems={'start'}
                                        p={'15px !important'}
                                    >
                                        <Typography
                                            text={'Insights'}
                                            customStyles={{ fontWeight: 500 }}
                                        />
                                    </Grid>
                                    <Grid item container p={'0 !important'}>
                                        <Grid
                                            item
                                            xs={1}
                                            display={'flex'}
                                            justifyContent={'flex-start'}
                                            alignItems={'center'}
                                        >
                                            <Box
                                                sx={{
                                                    cursor: 'pointer',
                                                    display: 'flex',
                                                    width: 'var(--spacing-4, 32px)',
                                                    height: 'var(--spacing-4, 32px)',
                                                    padding:
                                                        'var(--spacing-none, 8px)',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    gap: 'var(--spacing-none, 0px)',
                                                    borderRadius: '666.667px',
                                                    border: '1px solid var(--Icon-Icon-Border, #E5E6EB)',
                                                    backgroundColor: (theme) =>
                                                        theme.backgroundColor
                                                            .primary,
                                                }}
                                            >
                                                {isDarkMode ? (
                                                    <LeftArrowDarkMode
                                                        onClick={
                                                            handlePrevClick
                                                        }
                                                        width={16}
                                                        height={16}
                                                    />
                                                ) : (
                                                    <LeftArrow
                                                        onClick={
                                                            handlePrevClick
                                                        }
                                                        width={16}
                                                        height={16}
                                                    />
                                                )}
                                            </Box>
                                        </Grid>
                                        <Grid
                                            item
                                            container
                                            xs={10}
                                            display={'flex'}
                                            justifyContent={'center'}
                                            alignItems={'center'}
                                            p={'16px 0px'}
                                            flexDirection={'column'}
                                        >
                                            <Grid item>
                                                {isLoading ? (
                                                    <Skeleton
                                                        width={150}
                                                        height={50}
                                                    />
                                                ) : (
                                                    cashInsightItems[
                                                        currentIndex
                                                    ] && (
                                                        <Typography
                                                            text={
                                                                cashInsightItems[
                                                                    currentIndex
                                                                ].title
                                                            }
                                                            customStyles={{
                                                                fontSize:
                                                                    isScreenLowerThan1230
                                                                        ? 24
                                                                        : 32,
                                                                fontWeight: 700,
                                                            }}
                                                        />
                                                    )
                                                )}
                                            </Grid>
                                            <Grid item>
                                                {cashInsightItems[
                                                    currentIndex
                                                ] && (
                                                    <Typography
                                                        text={
                                                            cashInsightItems[
                                                                currentIndex
                                                            ].content
                                                        }
                                                        customStyles={{
                                                            fontSize: 16,
                                                            fontWeight: 400,
                                                            color: (theme) =>
                                                                theme.textColor
                                                                    .primary,
                                                        }}
                                                    />
                                                )}
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={1}
                                            display={'flex'}
                                            justifyContent={'flex-end'}
                                            alignItems={'center'}
                                        >
                                            <Box
                                                sx={{
                                                    cursor: 'pointer',
                                                    display: 'flex',
                                                    width: 'var(--spacing-4, 32px)',
                                                    height: 'var(--spacing-4, 32px)',
                                                    padding:
                                                        'var(--spacing-none, 8px)',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    gap: 'var(--spacing-none, 0px)',
                                                    borderRadius: '666.667px',
                                                    border: '1px solid var(--Icon-Icon-Border, #E5E6EB)',
                                                    backgroundColor: (theme) =>
                                                        theme.backgroundColor
                                                            .primary,
                                                }}
                                            >
                                                {isDarkMode ? (
                                                    <RightArrowDarkMode
                                                        onClick={
                                                            handleNextClick
                                                        }
                                                        width={16}
                                                        height={16}
                                                    />
                                                ) : (
                                                    <RightArrow
                                                        onClick={
                                                            handleNextClick
                                                        }
                                                        width={16}
                                                        height={16}
                                                    />
                                                )}
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Grid item width={'100%'}>
                                        <Divider
                                            sx={{
                                                background: (theme) =>
                                                    theme.palette.mode ===
                                                    'dark'
                                                        ? '#394150'
                                                        : '#E5E6EB',
                                            }}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        pt={
                                            isScreenLowerThan1024
                                                ? 'unset'
                                                : '48px '
                                        }
                                    >
                                        {isLoading ? (
                                            <Box
                                                display={'flex'}
                                                flexDirection={'column'}
                                                alignItems={'center'}
                                            >
                                                <Skeleton
                                                    width={300}
                                                    height={50}
                                                />
                                                <Skeleton
                                                    width={150}
                                                    height={50}
                                                />
                                            </Box>
                                        ) : (
                                            cashInsightItems[currentIndex] && (
                                                <Typography
                                                    text={
                                                        cashInsightItems[
                                                            currentIndex
                                                        ].text
                                                    }
                                                    customStyles={{
                                                        fontSize: 14,
                                                        fontWeight: 400,
                                                        color: (theme) =>
                                                            theme.textColor
                                                                .primary,
                                                        textAlign: 'center',
                                                    }}
                                                />
                                            )
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                sm={6}
                                xs={12}
                                md={4}
                                lg={4}
                                sx={{ m: '24px 0 24px 30px' }}
                            >
                                <Grid
                                    container
                                    item
                                    sx={styles.chartCard}
                                    spacing={2}
                                    px={2.5}
                                    py={0}
                                    height={
                                        isScreenLowerThan1024
                                            ? '326px'
                                            : '406px'
                                    }
                                    justifyContent={'center'}
                                >
                                    <Grid item>
                                        <Typography
                                            text={'Cash Positions'}
                                            customStyles={{ fontWeight: 500 }}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        width={'100%'}
                                        p={'0 !important'}
                                    >
                                        {isLoading ? (
                                            <>
                                                <Grid item xs={12}>
                                                    <Skeleton
                                                        width={'100%'}
                                                        height={10}
                                                    />
                                                    <Skeleton
                                                        width={'100%'}
                                                        height={10}
                                                    />
                                                    <Skeleton
                                                        width={'100%'}
                                                        height={10}
                                                    />
                                                    <Skeleton
                                                        width={'100%'}
                                                        height={10}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Skeleton
                                                        width={'100%'}
                                                        height={10}
                                                    />
                                                    <Skeleton
                                                        width={'100%'}
                                                        height={10}
                                                    />
                                                    <Skeleton
                                                        width={'100%'}
                                                        height={10}
                                                    />
                                                    <Skeleton
                                                        width={'100%'}
                                                        height={10}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Skeleton
                                                        width={'100%'}
                                                        height={10}
                                                    />
                                                    <Skeleton
                                                        width={'100%'}
                                                        height={10}
                                                    />
                                                    <Skeleton
                                                        width={'100%'}
                                                        height={10}
                                                    />
                                                    <Skeleton
                                                        width={'100%'}
                                                        height={10}
                                                    />
                                                </Grid>
                                            </>
                                        ) : (
                                            <CashPositionChart
                                                data={{
                                                    labels: cashDetail.cash_position.map(
                                                        (cash) => cash.month,
                                                    ),
                                                    datasets:
                                                        cashDetail.cash_position.map(
                                                            (cashPosition) => {
                                                                return {
                                                                    label: '',
                                                                    data: [
                                                                        cashPosition.cash_balance,
                                                                    ],
                                                                    backgroundColor:
                                                                        '#00C791',
                                                                    barThickness: 12,
                                                                    maxBarThickness: 12,
                                                                    borderRadius: 5,
                                                                };
                                                            },
                                                        ),
                                                }}
                                                isDarkMode={
                                                    themeMode === 'dark'
                                                }
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </SwipeableViews>
                    </>
                ) : (
                    <>
                        <Grid item sm={6} xs={12} md={4} lg={4}>
                            <Grid
                                container
                                item
                                sx={{ ...styles.cards }}
                                spacing={2}
                            >
                                <Grid item>
                                    <CashBalanceIcon
                                        width={
                                            isScreenLowerThan1024
                                                ? '120px'
                                                : '140px'
                                        }
                                        height={
                                            isScreenLowerThan1024
                                                ? '120px'
                                                : '140px'
                                        }
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography
                                        text="Cash Balance"
                                        customStyles={{
                                            fontWeight: 500,
                                        }}
                                    />
                                </Grid>
                                <Grid item>
                                    {isLoading ? (
                                        <Skeleton width={140} height={50} />
                                    ) : (
                                        <Typography
                                            text={cashDetail.cash_balance}
                                            customStyles={{
                                                fontWeight: 700,
                                                fontSize: 32,
                                            }}
                                        />
                                    )}
                                </Grid>
                                <Grid
                                    item
                                    container
                                    spacing={2}
                                    justifyContent={'space-around'}
                                >
                                    <Grid
                                        item
                                        display={'flex'}
                                        justifyContent={'center'}
                                        alignItems={'center'}
                                    >
                                        {/* {isLoading ? (
                                            <Skeleton
                                                variant="circular"
                                                width={25}
                                                height={25}
                                            />
                                        ) : (
                                            <ArrowUp />
                                        )}
                                        &nbsp;
                                        {isLoading ? (
                                            <Skeleton width={250} height={50} />
                                        ) : (
                                            <Typography
                                                text="23% compared to May 2023"
                                                customStyles={{
                                                    fontWeight: 400,
                                                    color: (theme) =>
                                                        theme.textColor.primary,
                                                }}
                                            />
                                        )} */}
                                    </Grid>
                                </Grid>
                                {/* </>
                                )} */}
                            </Grid>
                        </Grid>
                        <Grid item sm={6} xs={12} md={4} lg={4}>
                            <Grid container item sx={styles.cards} spacing={2}>
                                <Grid item>
                                    <CardBalanceIcon
                                        width={
                                            isScreenLowerThan1024
                                                ? '120px'
                                                : '140px'
                                        }
                                        height={
                                            isScreenLowerThan1024
                                                ? '120px'
                                                : '140px'
                                        }
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography
                                        text="Card Balance"
                                        customStyles={{ fontWeight: 500 }}
                                    />
                                </Grid>
                                <Grid item>
                                    {isLoading ? (
                                        <Skeleton width={140} height={50} />
                                    ) : (
                                        <Typography
                                            text={cashDetail.card_balance}
                                            customStyles={{
                                                fontWeight: 700,
                                                fontSize: 32,
                                            }}
                                        />
                                    )}
                                </Grid>
                                <Grid
                                    item
                                    container
                                    spacing={2}
                                    justifyContent={'space-around'}
                                >
                                    <Grid
                                        item
                                        display={'flex'}
                                        justifyContent={'center'}
                                        alignItems={'center'}
                                    >
                                        {isLoading ? (
                                            <Skeleton
                                                variant="circular"
                                                width={25}
                                                height={25}
                                            />
                                        ) : (
                                            <ArrowDown />
                                        )}{' '}
                                        &nbsp;
                                        {isLoading ? (
                                            <Skeleton width={250} height={50} />
                                        ) : (
                                            <Typography
                                                text="-12% compared to May 2023"
                                                customStyles={{
                                                    fontWeight: 400,
                                                    color: (theme) =>
                                                        theme.textColor.primary,
                                                }}
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item sm={6} xs={12} md={4} lg={4}>
                            <Grid
                                container
                                item
                                sx={styles.chartCard}
                                spacing={2}
                                justifyContent={'center'}
                                px={2.5}
                                height={
                                    isScreenLowerThan1024 ? '326px' : '406px'
                                }
                            >
                                <Grid item>
                                    <Typography
                                        text={'Cash In, Cash Out'}
                                        customStyles={{ fontWeight: 500 }}
                                    />
                                </Grid>
                                {isLoading ? (
                                    <>
                                        <Grid item xs={12}>
                                            <Skeleton
                                                width={'100%'}
                                                height={10}
                                            />
                                            <Skeleton
                                                width={'100%'}
                                                height={10}
                                            />
                                            <Skeleton
                                                width={'100%'}
                                                height={10}
                                            />
                                            <Skeleton
                                                width={'100%'}
                                                height={10}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Skeleton
                                                width={'100%'}
                                                height={10}
                                            />
                                            <Skeleton
                                                width={'100%'}
                                                height={10}
                                            />
                                            <Skeleton
                                                width={'100%'}
                                                height={10}
                                            />
                                            <Skeleton
                                                width={'100%'}
                                                height={10}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Skeleton
                                                width={'100%'}
                                                height={10}
                                            />
                                            <Skeleton
                                                width={'100%'}
                                                height={10}
                                            />
                                            <Skeleton
                                                width={'100%'}
                                                height={10}
                                            />
                                            <Skeleton
                                                width={'100%'}
                                                height={10}
                                            />
                                        </Grid>
                                    </>
                                ) : (
                                    <CashInCashOutBar
                                        data={{
                                            labels: cashDetail.cash_in_out.map(
                                                (cash) => cash.month,
                                            ),
                                            datasets:
                                                cashDetail.cash_in_out.map(
                                                    (cashInOut) => {
                                                        return {
                                                            label: '',
                                                            data: [
                                                                cashInOut.cash_in,
                                                                cashInOut.cash_out,
                                                            ],
                                                            backgroundColor:
                                                                '#00C791',
                                                            barThickness: 12,
                                                            maxBarThickness: 12,
                                                            borderRadius: 5,
                                                        };
                                                    },
                                                ),
                                        }}
                                        isDarkMode={themeMode === 'dark'}
                                    />
                                )}
                            </Grid>
                        </Grid>

                        <Grid item sm={6} xs={12} md={4} lg={4}>
                            <Grid
                                container
                                item
                                sx={styles.chartCard}
                                spacing={2}
                                px={2.5}
                                height={
                                    isScreenLowerThan1024 ? '326px' : '406px'
                                }
                                justifyContent={'center'}
                            >
                                <Grid item>
                                    <Typography
                                        text={'Revenue'}
                                        customStyles={{ fontWeight: 500 }}
                                    />
                                </Grid>
                                {isLoading ? (
                                    <>
                                        <Grid item xs={12}>
                                            <Skeleton
                                                width={'100%'}
                                                height={10}
                                            />
                                            <Skeleton
                                                width={'100%'}
                                                height={10}
                                            />
                                            <Skeleton
                                                width={'100%'}
                                                height={10}
                                            />
                                            <Skeleton
                                                width={'100%'}
                                                height={10}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Skeleton
                                                width={'100%'}
                                                height={10}
                                            />
                                            <Skeleton
                                                width={'100%'}
                                                height={10}
                                            />
                                            <Skeleton
                                                width={'100%'}
                                                height={10}
                                            />
                                            <Skeleton
                                                width={'100%'}
                                                height={10}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Skeleton
                                                width={'100%'}
                                                height={10}
                                            />
                                            <Skeleton
                                                width={'100%'}
                                                height={10}
                                            />
                                            <Skeleton
                                                width={'100%'}
                                                height={10}
                                            />
                                            <Skeleton
                                                width={'100%'}
                                                height={10}
                                            />
                                        </Grid>
                                    </>
                                ) : (
                                    <RevenueMonthChart
                                        data={{
                                            labels: revenueDetail.revenue_months.map(
                                                (cash) => cash.month,
                                            ),
                                            datasets:
                                                revenueDetail.revenue_months.map(
                                                    (revenue) => {
                                                        return {
                                                            label: '',
                                                            data: [
                                                                revenue.revenue,
                                                                revenue.cost_of_revenue,
                                                            ],
                                                            backgroundColor:
                                                                '#0061DB',
                                                            barThickness: 12,
                                                            maxBarThickness: 12,
                                                        };
                                                    },
                                                ),
                                        }}
                                        isDarkMode={themeMode === 'dark'}
                                    />
                                )}
                            </Grid>
                        </Grid>
                        <Grid item sm={6} xs={12} md={4} lg={4}>
                            <Grid
                                container
                                item
                                sx={{
                                    ...styles.cards,
                                    p: isScreenLowerThan1024
                                        ? '0'
                                        : '20px 0 0 0',
                                }}
                                spacing={2}
                                justifyContent={'center'}
                            >
                                <Grid item>
                                    <NetIncome
                                        width={
                                            isScreenLowerThan1024
                                                ? '120px'
                                                : '200px'
                                        }
                                        height={
                                            isScreenLowerThan1024
                                                ? '120px'
                                                : '200px'
                                        }
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography
                                        p={1}
                                        text="Net Income for This Year"
                                        customStyles={{ fontWeight: 500 }}
                                    />
                                </Grid>
                                <Grid item>
                                    {isLoading ? (
                                        <Skeleton height={50} width={250} />
                                    ) : (
                                        <Typography
                                            text={netIncome}
                                            customStyles={{
                                                fontWeight: 700,
                                                fontSize: 32,
                                            }}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item sm={6} xs={12} md={4} lg={4}>
                            <Grid
                                container
                                item
                                sx={styles.chartCard}
                                spacing={2}
                                px={2.5}
                                height={
                                    isScreenLowerThan1024 ? '326px' : '406px'
                                }
                                justifyContent={'center'}
                            >
                                <Grid item>
                                    <Typography
                                        text={'Operating Expenses'}
                                        customStyles={{ fontWeight: 500 }}
                                    />
                                </Grid>
                                {isLoading ? (
                                    <>
                                        <Grid item xs={12}>
                                            <Skeleton
                                                width={'100%'}
                                                height={10}
                                            />
                                            <Skeleton
                                                width={'100%'}
                                                height={10}
                                            />
                                            <Skeleton
                                                width={'100%'}
                                                height={10}
                                            />
                                            <Skeleton
                                                width={'100%'}
                                                height={10}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Skeleton
                                                width={'100%'}
                                                height={10}
                                            />
                                            <Skeleton
                                                width={'100%'}
                                                height={10}
                                            />
                                            <Skeleton
                                                width={'100%'}
                                                height={10}
                                            />
                                            <Skeleton
                                                width={'100%'}
                                                height={10}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Skeleton
                                                width={'100%'}
                                                height={10}
                                            />
                                            <Skeleton
                                                width={'100%'}
                                                height={10}
                                            />
                                            <Skeleton
                                                width={'100%'}
                                                height={10}
                                            />
                                            <Skeleton
                                                width={'100%'}
                                                height={10}
                                            />
                                        </Grid>
                                    </>
                                ) : (
                                    <OperatingExpensesChart
                                        data={operatingExpenses}
                                        isDarkMode={themeMode === 'dark'}
                                    />
                                )}
                            </Grid>
                        </Grid>

                        <Grid item sm={6} xs={12} md={4} lg={4}>
                            <Grid
                                container
                                item
                                sx={styles.chartCard}
                                spacing={2}
                                height={
                                    isScreenLowerThan1024 ? '326px' : '406px'
                                }
                                justifyContent={'center'}
                                px={2.5}
                                py={'0 !important'}
                            >
                                <Grid item>
                                    <Typography
                                        text={'Top Expenses This Month'}
                                        customStyles={{ fontWeight: 500 }}
                                    />
                                </Grid>
                                {isLoading ? (
                                    <>
                                        <Grid item xs={12}>
                                            <Skeleton
                                                width={'100%'}
                                                height={10}
                                            />
                                            <Skeleton
                                                width={'100%'}
                                                height={10}
                                            />
                                            <Skeleton
                                                width={'100%'}
                                                height={10}
                                            />
                                            <Skeleton
                                                width={'100%'}
                                                height={10}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Skeleton
                                                width={'100%'}
                                                height={10}
                                            />
                                            <Skeleton
                                                width={'100%'}
                                                height={10}
                                            />
                                            <Skeleton
                                                width={'100%'}
                                                height={10}
                                            />
                                            <Skeleton
                                                width={'100%'}
                                                height={10}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Skeleton
                                                width={'100%'}
                                                height={10}
                                            />
                                            <Skeleton
                                                width={'100%'}
                                                height={10}
                                            />
                                            <Skeleton
                                                width={'100%'}
                                                height={10}
                                            />
                                            <Skeleton
                                                width={'100%'}
                                                height={10}
                                            />
                                        </Grid>
                                    </>
                                ) : (
                                    <Grid
                                        item
                                        width={'100%'}
                                        p={'0 !important'}
                                    >
                                        <TopExpensesChart
                                            topExpensesData={
                                                revenueDetail.top_expenses_this_month
                                            }
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                        <Grid item sm={6} xs={12} md={4} lg={4}>
                            <Grid
                                container
                                item
                                sx={styles.chartCard}
                                spacing={2}
                                height={
                                    isScreenLowerThan1024 ? '326px' : '406px'
                                }
                                justifyContent={'center'}
                                px={2.5}
                                // alignItems={'center'}
                                py={'0 !important'}
                            >
                                <Grid
                                    item
                                    alignItems={'start'}
                                    p={'15px !important'}
                                >
                                    <Typography
                                        text={'Insights'}
                                        customStyles={{ fontWeight: 500 }}
                                    />
                                </Grid>
                                <Grid item container>
                                    <Grid
                                        item
                                        xs={1}
                                        display={'flex'}
                                        justifyContent={'flex-start'}
                                        alignItems={'center'}
                                    >
                                        <Box
                                            sx={{
                                                cursor: 'pointer',
                                                display: 'flex',
                                                width: 'var(--spacing-4, 32px)',
                                                height: 'var(--spacing-4, 32px)',
                                                padding:
                                                    'var(--spacing-none, 8px)',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                gap: 'var(--spacing-none, 0px)',
                                                borderRadius: '666.667px',
                                                border: '1px solid var(--Icon-Icon-Border, #E5E6EB)',
                                                backgroundColor: (theme) =>
                                                    theme.backgroundColor
                                                        .primary,
                                            }}
                                        >
                                            {isDarkMode ? (
                                                <LeftArrowDarkMode
                                                    onClick={handlePrevClick}
                                                    width={16}
                                                    height={16}
                                                />
                                            ) : (
                                                <LeftArrow
                                                    onClick={handlePrevClick}
                                                    width={16}
                                                    height={16}
                                                />
                                            )}
                                        </Box>
                                    </Grid>
                                    <Grid
                                        item
                                        container
                                        xs={10}
                                        display={'flex'}
                                        justifyContent={'center'}
                                        alignItems={'center'}
                                        p={'16px 10px'}
                                        flexDirection={'column'}
                                        textAlign={'center'}
                                    >
                                        <Grid item>
                                            {isLoading ? (
                                                <Skeleton
                                                    width={150}
                                                    height={50}
                                                />
                                            ) : (
                                                cashInsightItems[
                                                    currentIndex
                                                ] && (
                                                    <Typography
                                                        text={
                                                            cashInsightItems[
                                                                currentIndex
                                                            ].title
                                                        }
                                                        customStyles={{
                                                            fontSize:
                                                                isScreenLowerThan1230
                                                                    ? 24
                                                                    : 32,
                                                            fontWeight: 700,
                                                        }}
                                                    />
                                                )
                                            )}
                                        </Grid>
                                        {isLoading ? (
                                            <Skeleton width={150} height={50} />
                                        ) : (
                                            cashInsightItems[currentIndex] && (
                                                <Grid item>
                                                    <Typography
                                                        text={
                                                            cashInsightItems[
                                                                currentIndex
                                                            ].content
                                                        }
                                                        customStyles={{
                                                            fontSize: 16,
                                                            fontWeight: 400,
                                                            color: (theme) =>
                                                                theme.textColor
                                                                    .primary,
                                                        }}
                                                    />
                                                </Grid>
                                            )
                                        )}
                                    </Grid>
                                    <Grid
                                        item
                                        xs={1}
                                        display={'flex'}
                                        justifyContent={'flex-end'}
                                        alignItems={'center'}
                                    >
                                        <Box
                                            sx={{
                                                cursor: 'pointer',
                                                display: 'flex',
                                                width: 'var(--spacing-4, 32px)',
                                                height: 'var(--spacing-4, 32px)',
                                                padding:
                                                    'var(--spacing-none, 8px)',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                gap: 'var(--spacing-none, 0px)',
                                                borderRadius: '666.667px',
                                                border: '1px solid var(--Icon-Icon-Border, #E5E6EB)',
                                                backgroundColor: (theme) =>
                                                    theme.backgroundColor
                                                        .primary,
                                            }}
                                        >
                                            {isDarkMode ? (
                                                <RightArrowDarkMode
                                                    onClick={handleNextClick}
                                                    width={16}
                                                    height={16}
                                                />
                                            ) : (
                                                <RightArrow
                                                    onClick={handleNextClick}
                                                    width={16}
                                                    height={16}
                                                />
                                            )}
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Grid item width={'100%'}>
                                    <Divider
                                        sx={{
                                            background: (theme) =>
                                                theme.palette.mode === 'dark'
                                                    ? '#394150'
                                                    : '#E5E6EB',
                                        }}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    pt={
                                        isScreenLowerThan1024
                                            ? 'unset'
                                            : '48px '
                                    }
                                >
                                    {isLoading ? (
                                        <Box
                                            display={'flex'}
                                            flexDirection={'column'}
                                            alignItems={'center'}
                                        >
                                            <Skeleton width={300} height={50} />
                                            <Skeleton width={150} height={50} />
                                        </Box>
                                    ) : (
                                        cashInsightItems[currentIndex] && (
                                            <Typography
                                                text={
                                                    cashInsightItems[
                                                        currentIndex
                                                    ].text
                                                }
                                                customStyles={{
                                                    fontSize: 14,
                                                    fontWeight: 400,
                                                    color: (theme) =>
                                                        theme.textColor.primary,
                                                    textAlign: 'center',
                                                }}
                                            />
                                        )
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item sm={6} xs={12} md={4} lg={4}>
                            <Grid
                                container
                                item
                                sx={styles.chartCard}
                                spacing={2}
                                px={2.5}
                                py={0}
                                height={
                                    isScreenLowerThan1024 ? '326px' : '406px'
                                }
                                justifyContent={'center'}
                            >
                                <Grid item>
                                    <Typography
                                        text={'Cash Positions'}
                                        customStyles={{ fontWeight: 500 }}
                                    />
                                </Grid>
                                {isLoading ? (
                                    <>
                                        <Grid item xs={12}>
                                            <Skeleton
                                                width={'100%'}
                                                height={10}
                                            />
                                            <Skeleton
                                                width={'100%'}
                                                height={10}
                                            />
                                            <Skeleton
                                                width={'100%'}
                                                height={10}
                                            />
                                            <Skeleton
                                                width={'100%'}
                                                height={10}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Skeleton
                                                width={'100%'}
                                                height={10}
                                            />
                                            <Skeleton
                                                width={'100%'}
                                                height={10}
                                            />
                                            <Skeleton
                                                width={'100%'}
                                                height={10}
                                            />
                                            <Skeleton
                                                width={'100%'}
                                                height={10}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Skeleton
                                                width={'100%'}
                                                height={10}
                                            />
                                            <Skeleton
                                                width={'100%'}
                                                height={10}
                                            />
                                            <Skeleton
                                                width={'100%'}
                                                height={10}
                                            />
                                            <Skeleton
                                                width={'100%'}
                                                height={10}
                                            />
                                        </Grid>
                                    </>
                                ) : (
                                    <Grid
                                        item
                                        width={'100%'}
                                        p={'0 !important'}
                                    >
                                        <CashPositionChart
                                            data={{
                                                labels: cashDetail.cash_position.map(
                                                    (cash) => cash.month,
                                                ),
                                                datasets:
                                                    cashDetail.cash_position.map(
                                                        (cashPosition) => {
                                                            return {
                                                                label: '',
                                                                data: [
                                                                    cashPosition.cash_balance,
                                                                ],
                                                                backgroundColor:
                                                                    '#00C791',
                                                                barThickness: 12,
                                                                maxBarThickness: 12,
                                                                borderRadius: 5,
                                                            };
                                                        },
                                                    ),
                                            }}
                                            isDarkMode={themeMode === 'dark'}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </>
                )}
            </Grid>
            <Grid item container sx={styles.reportContainer} spacing={2}>
                <Grid
                    container
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    justifyContent={'space-between'}
                >
                    <Grid item xs={8} md={4} lg={4}>
                        <Button
                            text={
                                isObjectEmpty(dateRange) ? (
                                    <Typography
                                        text={'Date Range'}
                                        customStyles={{
                                            color: (theme) =>
                                                theme.textColor.primary,
                                        }}
                                    />
                                ) : (
                                    <Typography
                                        text={`${dateRange.startDate} to ${dateRange.endDate}`}
                                        customStyles={{
                                            color: (theme) =>
                                                theme.textColor.primary,
                                            fontSize: isMobileScreen ? 14 : 16,
                                        }}
                                    />
                                )
                            }
                            size="small"
                            fullWidth
                            variant="outlined"
                            customStyles={{
                                backgroundColor: (theme) =>
                                    theme.backgroundColor.primary,
                                justifyContent: 'space-between',
                                p: '14px',
                                height: '48px',
                            }}
                            endIcon={
                                isObjectEmpty(dateRange) ? (
                                    <DownArrow />
                                ) : (
                                    <IconButton
                                        disableRipple
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setDateRange(null);
                                        }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                )
                            }
                            onClick={() => toggle()}
                        />
                        <Box
                            sx={{
                                position: 'absolute',
                                zIndex: 10000,
                                left: isMobileScreen ? 5 : 48,
                                width: isMobileScreen ? '95%' : 'auto',
                                overflow: isMobileScreen ? 'auto' : 'unset',
                                backgroundColor: isMobileScreen
                                    ? '#fff'
                                    : 'inherit',
                                display: isDateRangeOpen ? 'block' : 'none',
                            }}
                        >
                            <DateRangePicker
                                open={isDateRangeOpen}
                                toggle={toggle}
                                setDateRange={onChangeDateRange}
                            />
                        </Box>
                    </Grid>
                    <Grid
                        item
                        xs={4}
                        md={4}
                        lg={4}
                        display={'flex'}
                        justifyContent={'flex-end'}
                    >
                        <Button
                            text={isMobileScreen ? '' : 'Export Report'}
                            size="small"
                            variant="outlined"
                            customStyles={{
                                backgroundColor: (theme) =>
                                    theme.backgroundColor.primary,
                                color: (theme) => theme.textColor.primary,
                                justifyContent: 'space-between',
                                p: '14px',
                                fontSize: '16px',
                                fontWeight: 400,
                                '& .MuiButton-endIcon': {
                                    mr: isScreenLowerThan1024 ? '0px' : '8px',
                                },
                                height: '48px',
                            }}
                            endIcon={
                                isMobileScreen ? <Export /> : <DownArrow />
                            }
                            onClick={handleClick}
                        />
                    </Grid>
                </Grid>

                <Grid
                    pb={0}
                    container
                    item
                    width={'100%'}
                    spacing={0}
                    wrap="nowrap"
                    sx={{
                        cursor: 'pointer',
                        overflowX: 'auto',
                        maxWidth: '100%', // Set a maximum width
                        '-ms-overflow-style': 'none', // Hide scrollbar in IE and Edge
                        scrollbarWidth: 'none', // Hide scrollbar in Firefox
                        '&::-webkit-scrollbar': {
                            display: 'none', // Hide scrollbar in WebKit browsers
                        },
                    }}
                >
                    <Grid item>
                        <Box
                            sx={{
                                p: '10px 28px',
                                borderRadius: '12px 12px 0px 0px',
                                backgroundColor: (theme) =>
                                    balanceOption === 1
                                        ? theme.backgroundColor.secondary
                                        : theme.backgroundColor.primary,
                            }}
                            onClick={() => handleBalanceOptionChange(1)}
                        >
                            <Typography
                                text="Cash Activity"
                                customStyles={{
                                    fontWeight: 500,
                                    fontSize: '18px',
                                    whiteSpace: 'nowrap', // Prevent text from wrapping
                                    color: (theme) =>
                                        balanceOption === 1
                                            ? theme.textColor.dark
                                            : theme.textColor.light,
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box
                            sx={{
                                p: '10px 28px',
                                borderRadius: '12px 12px 0px 0px',
                                backgroundColor: (theme) =>
                                    balanceOption === 2
                                        ? theme.backgroundColor.secondary
                                        : theme.backgroundColor.primary,
                            }}
                            onClick={() => handleBalanceOptionChange(2)}
                        >
                            <Typography
                                text="Profit & Loss"
                                customStyles={{
                                    fontWeight: 500,
                                    fontSize: '18px',
                                    whiteSpace: 'nowrap', // Prevent text from wrapping
                                    color: (theme) =>
                                        balanceOption === 2
                                            ? theme.textColor.dark
                                            : theme.textColor.light,
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid item>
                        <Box
                            sx={{
                                p: '10px 28px',
                                borderRadius: '12px 12px 0px 0px',
                                backgroundColor: (theme) =>
                                    balanceOption === 3
                                        ? theme.backgroundColor.secondary
                                        : theme.backgroundColor.primary,
                            }}
                            onClick={() => handleBalanceOptionChange(3)}
                        >
                            <Typography
                                text="Balance Sheet"
                                customStyles={{
                                    fontWeight: 500,
                                    fontSize: '18px',
                                    whiteSpace: 'nowrap', // Prevent text from wrapping
                                    color: (theme) =>
                                        balanceOption === 3
                                            ? theme.textColor.dark
                                            : theme.textColor.light,
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>
                <Grid container item sx={{ paddingTop: '0 !important' }}>
                    <Grid item width="100%" mb={2} pt={0}>
                        <Box sx={styles.reportDetailsContainer}>
                            {isObjectEmpty(summary) ? (
                                'Loading...'
                            ) : balanceOption === 1 ? (
                                <>
                                    <Grid
                                        p={1}
                                        item
                                        container
                                        justifyContent={'space-between'}
                                    >
                                        {/* TODO: Need to hide this button as we have a dynamic flow of accordions
                                        <Grid item>
                                            <Switch
                                                label={
                                                    <Typography
                                                        text={'Expand All'}
                                                        customStyles={{
                                                            fontSize: '14px',
                                                            fontWeight: 500,
                                                        }}
                                                    />
                                                }
                                                labelPlacement="start"
                                                checked={isSwitchOn}
                                                onClick={(e) =>
                                                    handleSwitchToggle(
                                                        e.target.checked,
                                                    )
                                                }
                                            />
                                        </Grid> */}
                                        {!isMobileScreen && (
                                            <Grid
                                                item
                                                display={'flex'}
                                                alignItems={'center'}
                                            >
                                                <Typography
                                                    text="Total"
                                                    customStyles={{
                                                        fontSize: 14,
                                                        fontWeight: 600,
                                                    }}
                                                />
                                            </Grid>
                                        )}
                                    </Grid>
                                    <Grid
                                        item
                                        sx={{ width: '100%', overflow: 'auto' }}
                                    >
                                        <GenericAccordion
                                            panels={Object.entries(summary).map(
                                                ([key, value], index) => {
                                                    return {
                                                        id: `panel${key}`,
                                                        heading: key,
                                                        subHeading:
                                                            key !== 'content'
                                                                ? new Intl.NumberFormat(
                                                                      'en-US',
                                                                      {
                                                                          style: 'decimal',
                                                                          minimumFractionDigits: 2,
                                                                          maximumFractionDigits: 2,
                                                                      },
                                                                  ).format(
                                                                      value.amount ??
                                                                          0,
                                                                  )
                                                                : undefined,
                                                        content:
                                                            value.additionalProps ??
                                                            null,
                                                    };
                                                },
                                            )}
                                            isMultiOpen={true}
                                            expanded={expanded}
                                            setExpanded={setExpanded}
                                            panelHeadingStyle={{
                                                fontSize: 16,
                                                fontWeight: 500,
                                                color: (theme) =>
                                                    theme.textColor.dark,
                                            }}
                                            customStyles={{
                                                // textTransform: 'uppercase',
                                                background: (theme) =>
                                                    theme.backgroundColor
                                                        .secondary,
                                                justifyContent: 'space-between',
                                                minHeight: '5px',
                                                '& .MuiAccordionSummary-root': {
                                                    margin: '5px 0',
                                                    backgroundColor: (theme) =>
                                                        theme.backgroundColor
                                                            .secondary,
                                                },
                                            }}
                                            onChange={handleAccordionChange}
                                            isLoading={isReportLoading}
                                        />
                                    </Grid>
                                </>
                            ) : balanceOption === 2 ? (
                                <>
                                    <Grid
                                        p={1}
                                        item
                                        container
                                        justifyContent={'space-between'}
                                    >
                                        {/* <Grid item>
                                            <Switch
                                                label={
                                                    <Typography
                                                        text={'Expand All'}
                                                        customStyles={{
                                                            fontSize: '14px',
                                                            fontWeight: 500,
                                                        }}
                                                    />
                                                }
                                                labelPlacement="start"
                                                checked={isSwitchOn}
                                                onClick={(e) =>
                                                    handleSwitchToggle(
                                                        e.target.checked,
                                                    )
                                                }
                                            />
                                        </Grid> */}
                                        {!isMobileScreen && (
                                            <Grid
                                                item
                                                display={'flex'}
                                                alignItems={'center'}
                                            >
                                                <Typography
                                                    text="Total"
                                                    customStyles={{
                                                        fontSize: 14,
                                                        fontWeight: 600,
                                                    }}
                                                />
                                            </Grid>
                                        )}
                                    </Grid>
                                    <Grid
                                        item
                                        sx={{ width: '100%', overflow: 'auto' }}
                                    >
                                        <GenericAccordion
                                            panels={Object.entries(summary).map(
                                                ([key, value], index) => {
                                                    return {
                                                        id: `panel${key}`,
                                                        heading: key,
                                                        subHeading:
                                                            key !== 'content'
                                                                ? new Intl.NumberFormat(
                                                                      'en-US',
                                                                      {
                                                                          style: 'decimal',
                                                                          minimumFractionDigits: 2,
                                                                          maximumFractionDigits: 2,
                                                                      },
                                                                  ).format(
                                                                      value.amount ??
                                                                          0,
                                                                  )
                                                                : undefined,
                                                        content:
                                                            value.additionalProps ??
                                                            null,
                                                    };
                                                },
                                            )}
                                            isMultiOpen={true}
                                            expanded={expanded}
                                            setExpanded={setExpanded}
                                            panelHeadingStyle={{
                                                fontSize: 16,
                                                fontWeight: 500,
                                                color: (theme) =>
                                                    theme.textColor.dark,
                                            }}
                                            customStyles={{
                                                // textTransform: 'uppercase',
                                                background: (theme) =>
                                                    theme.backgroundColor
                                                        .secondary,
                                                justifyContent: 'space-between',
                                                minHeight: '5px',
                                                '& .MuiAccordionSummary-root': {
                                                    margin: '5px 0',
                                                    backgroundColor: (theme) =>
                                                        theme.backgroundColor
                                                            .secondary,
                                                },
                                            }}
                                            onChange={handleAccordionChange}
                                            isLoading={isReportLoading}
                                        />
                                    </Grid>
                                </>
                            ) : (
                                <>
                                    <Grid
                                        p={1}
                                        item
                                        container
                                        justifyContent={'space-between'}
                                    >
                                        {/* <Grid item>
                                            <Switch
                                                label={
                                                    <Typography
                                                        text={'Expand All'}
                                                        customStyles={{
                                                            fontSize: '14px',
                                                            fontWeight: 500,
                                                        }}
                                                    />
                                                }
                                                labelPlacement="start"
                                                checked={isSwitchOn}
                                                onClick={(e) =>
                                                    handleSwitchToggle(
                                                        e.target.checked,
                                                    )
                                                }
                                            />
                                        </Grid> */}
                                        {!isMobileScreen && (
                                            <Grid
                                                item
                                                display={'flex'}
                                                alignItems={'center'}
                                            >
                                                <Typography
                                                    text="Total"
                                                    customStyles={{
                                                        fontSize: 14,
                                                        fontWeight: 600,
                                                    }}
                                                />
                                            </Grid>
                                        )}
                                    </Grid>
                                    <Grid
                                        item
                                        sx={{ width: '100%', overflow: 'auto' }}
                                    >
                                        <GenericAccordion
                                            panels={Object.entries(summary).map(
                                                ([key, value], index) => {
                                                    return {
                                                        id: `panel${key}`,
                                                        heading: key,
                                                        subHeading:
                                                            key !== 'content'
                                                                ? new Intl.NumberFormat(
                                                                      'en-US',
                                                                      {
                                                                          style: 'decimal',
                                                                          minimumFractionDigits: 2,
                                                                          maximumFractionDigits: 2,
                                                                      },
                                                                  ).format(
                                                                      value.amount ??
                                                                          0,
                                                                  )
                                                                : undefined,
                                                        content:
                                                            value.additionalProps ??
                                                            null,
                                                    };
                                                },
                                            )}
                                            isMultiOpen={true}
                                            expanded={expanded}
                                            setExpanded={setExpanded}
                                            panelHeadingStyle={{
                                                fontSize: 16,
                                                fontWeight: 500,
                                                color: (theme) =>
                                                    theme.textColor.dark,
                                            }}
                                            customStyles={{
                                                // textTransform: 'uppercase',
                                                background: (theme) =>
                                                    theme.backgroundColor
                                                        .secondary,
                                                justifyContent: 'space-between',
                                                minHeight: '5px',
                                                '& .MuiAccordionSummary-root': {
                                                    margin: '5px 0',
                                                    backgroundColor: (theme) =>
                                                        theme.backgroundColor
                                                            .secondary,
                                                },
                                            }}
                                            onChange={handleAccordionChange}
                                            isLoading={isReportLoading}
                                            selectedPanel={selectedPanel}
                                        />
                                    </Grid>
                                </>
                            )}
                        </Box>
                    </Grid>
                </Grid>

                <Menu
                    items={[
                        // {
                        //     name: (
                        //         <Grid container spacing={2}>
                        //             <Grid item>
                        //                 <ExcelIcon />
                        //             </Grid>
                        //             <Grid item>
                        //                 <Typography
                        //                     text={'Export as Excel'}
                        //                     customStyles={{
                        //                         fontSize: 14,
                        //                         fontWeight: 500,
                        //                         color: (theme) =>
                        //                             theme.textColor.primary,
                        //                     }}
                        //                 />
                        //             </Grid>
                        //         </Grid>
                        //     ),
                        //     onClick: () => {},
                        // },
                        {
                            name: (
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <CSV />
                                    </Grid>
                                    <Grid item>
                                        <Typography
                                            text={'Export as CSV'}
                                            customStyles={{
                                                fontSize: 14,
                                                fontWeight: 500,
                                                color: (theme) =>
                                                    theme.textColor.primary,
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            ),
                            onClick: () => {
                                handleDownLoadCSV();
                            },
                        },
                    ]}
                    anchorEl={anchorEl}
                    handleClose={handleCloseMenu}
                    customStyles={{ width: '254px' }}
                />
            </Grid>
        </Grid>
    );
};
