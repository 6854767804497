import { useCallback } from 'react';
import { useAxios } from '../../hooks/useAxios';

export const useTransactionService = () => {
    const { get, patch, post, remove, isLoading } = useAxios();

    const getTransactionsByCompany = useCallback(
        (companyId, page) =>
            get(`bookkeeper/get/transactions/posted/${companyId}?page=${page}`),
        [get],
    );

    const getTransactionsByChat = useCallback(
        (chatId) => get(`bookkeeper/get/transactions/chat/${chatId}`),
        [get],
    );

    const updatedTransaction = (transactionId, transaction) =>
        patch(
            `bookkeeper/${transactionId}/edit/transactions`,
            transaction,
            'Updated Successfully',
        );

    const confirmTransaction = (transactionIds) =>
        post(
            `bookkeeper/confirm/transactions`,
            { transaction_ids: transactionIds },
            '',
        );

    //Partially edit an existing transaction or its items for a company.
    //Include in req.body journal_entries: [{account: account.id, amount: 10, entry_type: Debit}, {account:account.id, amount: 10, entry_type: Credit}]
    const updatedPartialTransaction = (transactionId, transaction) =>
        patch(
            `bookkeeper/${transactionId}/edit/transaction/accountant_mode/`,
            transaction,
            'Updated Successfully',
        );

    const undoTransaction = (transactionId, transaction) =>
        post(
            `bookkeeper/unpost/transaction/${transactionId}`,
            transaction,
            'Transaction unposted successfully',
        );

    const deleteTransaction = (transactionId) =>
        remove(
            `bookkeeper/delete/transaction/${transactionId}`,
            {},
            'Transaction deleted successfully',
        );

    const getPendingTransactionsForReview = useCallback(
        (companyId) =>
            get(`bookkeeper/get/transactions/pendingreview/${companyId}`),
        [get],
    );
    return {
        isLoading,
        undoTransaction,
        deleteTransaction,
        updatedTransaction,
        confirmTransaction,
        getTransactionsByChat,
        getTransactionsByCompany,
        updatedPartialTransaction,
        getPendingTransactionsForReview,
    };
};
