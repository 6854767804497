import { combineReducers } from '@reduxjs/toolkit';
import meSlice from './me.slice';
import authSlice from './auth.slice';
import companySlice from './company.slice';
import chatSlice from './chat.slice';

const rootReducer = combineReducers({
    me: meSlice,
    auth: authSlice,
    chat: chatSlice,
    company: companySlice,
});
export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;
