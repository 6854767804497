import React, { useState } from 'react';
import { Box, Grid } from '@mui/material';
import { ChatBox } from './ChatBox';
import UploadFile from '../UploadFile';
import { ChatInput } from './ChatInput';
import { ChatHeader } from './ChatHeader';
import { Modal, Typography } from '../../shared';
import { useThemeToggle } from '../../hooks/useThemeToggle';

const styles = {
    chatPanel: {
        display: 'flex',
        padding: '24px',
        justifyContent: 'space-between',
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: '12px',
        background: (theme) => theme.backgroundColor.primary,
        height: '650px',
    },
};

const Chat = (props) => {
    const {
        handleSendMessage,
        chatBoxMessage,
        setChatBoxMessage,
        selectedFiles,
        setSelectedFiles,
        isLoading,
    } = props;
    const [transactionType, setTransactionType] = useState('Expense');
    const [isFileUploadModalOpen, setIsFileUploadModalOpen] = useState(false);
    const { themeMode } = useThemeToggle();
    const openFileUploadModal = () => {
        setIsFileUploadModalOpen(true);
    };

    const handleCloseModal = () => {
        // setSelectedFiles([]);
        setIsFileUploadModalOpen(!isFileUploadModalOpen);
    };

    const handleUploadFile = () => {
        setIsFileUploadModalOpen(!isFileUploadModalOpen);
    };

    return (
        <Box sx={styles.chatPanel}>
            <ChatHeader
                isDarkMode={themeMode === 'dark'}
                setTransactionType={setTransactionType}
                transactionType={transactionType}
            />
            <ChatBox
                isLoading={isLoading}
                isDarkMode={themeMode === 'dark'}
                setSelectedFiles={setSelectedFiles}
                chatBoxMessage={chatBoxMessage}
            />
            <ChatInput
                isLoading={isLoading}
                selectedFiles={selectedFiles}
                setSelectedFiles={setSelectedFiles}
                openFileUploadModal={openFileUploadModal}
                handleSendMessage={(userInput) => {
                    setChatBoxMessage([
                        ...chatBoxMessage,
                        {
                            id: Math.random(),
                            sender: 'receiver',
                            message: userInput || '',
                            timestamp: new Date().toISOString(),
                            ...(selectedFiles.length > 0 && {
                                files: selectedFiles.map((file) => ({
                                    chat_item: Math.random(),
                                    file_url: file.name,
                                })),
                            }),
                        },
                    ]);

                    handleSendMessage(
                        selectedFiles,
                        userInput,
                        transactionType,
                    );
                }}
            />

            <Modal
                fullWidth
                title="Import File"
                showFooter={true}
                actionBtnTitle={
                    <Typography
                        text={'Upload'}
                        customStyles={{
                            fontWeight: 500,
                            color: '#FFF',
                        }}
                    />
                }
                open={isFileUploadModalOpen}
                actionHandler={handleUploadFile}
                handleClose={handleCloseModal}
                saveButtonStyle={{ color: '#FFF' }}
                dividers={true}
                customCancelBtnStyle={{
                    backgroundColor: (theme) => theme.backgroundColor.primary,
                }}
            >
                <Grid container spacing={3} justifyContent={'center'}>
                    <Grid item xs={12}>
                        <UploadFile
                            onChange={(e) => setSelectedFiles(e.target.value)}
                            primaryText="Select a JPEG, PNG or PDF file to upload"
                            secondaryText="or drag and drop it here"
                            selectedFiles={selectedFiles}
                            setSelectedFiles={setSelectedFiles}
                            allowedImages={true}
                        />
                    </Grid>
                </Grid>
            </Modal>
        </Box>
    );
};

export default Chat;
